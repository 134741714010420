<template>

    <section id="faq" class="feedback-area ptb-80 bg-f9fafb">

                <div class="container" style="margin-top: 5rem">

                    <div id="your-decision" class="section-title faq">
                        <h2 class="titleFaq" v-on:click="faqToggle('one')">Selfpetition AI <span style="text-transform: lowercase">vs.</span> Starter Kit <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.one">
                            <div class="bar" style="margin-left: 0"></div>

                            <ProductsCompared :contains-faq="containsFaq"></ProductsCompared>

                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Althought we have helped almost 140 EB2 NIW applicants, our products are not suitable for everyone.</div>
                                <ul style="margin-top: 1rem">
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Yes, if you want to self petition and file documents by yourself:</span> If you're comfortable with the process of self petitioning for an EB-2 NIW and are confident in your ability to compile and submit all the required documents, Selfpetition AI or Starter Kit would be a helpful resource.</li>
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Yes, if you want to self petition with a lawyer but want to improve NIW endeavor, draft cover letter, or reference letters by yourself:</span> If you're planning to work with a lawyer to guide you through the process but want assistance in enhancing cover letter or reference letters.</li>
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Yes, if you're only exploring your options</span> and want to understand what's required in the EB-2 NIW process, the Starter Kit could be a more affordable way to gather information.</li>
                                </ul>
                            <div style="margin: 1rem; max-width: 1000px">However, our products might no be suitable for some individuals:</div>
                                <ul style="margin-top: 1rem">
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">No, if you expect us to put together the entire application for you:</span> If you need a service that handles the entire application process, from drafting and gathering evidence to completing forms and submitting them. We do not offer that level of support.</li>
                                </ul>
                            <div style="margin: 1rem; max-width: 1000px">We're always here to answer any questions you might have and help you figure out if the Starter Kit or Selfpetition AI is the right fit.</div>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/contact">Contact us</a>
                        </div>
                    </div>
                    
                    <div id="documents-list" class="section-title faq" style="padding-top: 5rem;">
                        <h2 class="titleFaq" v-on:click="faqToggle('two')">What's in the Starter Kit? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.two">
                            <div class="bar" style="margin-left: 0"></div>
                            <h6 class="subTitleFaq">Starter Kit</h6>
                                <ul style="margin-top: 1rem">
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Starter Kit Instructions</span> (Document #1)</li>
                                        <div style="color: #6084a4; margin-top: 5px">Explains Starter Kit structure, importance of repetitions and choosing the right language for your application.</div>
                                    
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Sample I-140 Cover Letter</span> -> profession of your choice (Document #2 ***)</li>
                                        <div style="color: #6084a4; margin-top: 5px">Find list of professions in the section <a href="/#starterKitProfessions" style="text-decoration: underline; font-weight: 500">STARTER KIT TEMPLATES</a>. During checkout, you can choose a maximum of two professions. Opting for two professions will get you two sets of Document #2 and Document #3. If your profession isn't listed, check Selfpetition AI or contact us, we're continuously incorporating new templates.</div>
                                    
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">NIW Case Builder</span> -> profession of your choice (Document #3)</li>
                                        <div style="color: #6084a4; margin-top: 5px">We offer sample cover letters tailored to specific professions to provide guidance on creating your own. However, it's important to note that the responsibilities of mid-level and senior professionals differ, as well as the same role aacross various industries. As a result, the provided letter serves as a sample rather than one you directly sign and send to USCIS. Essentially, it's a template meant to be customized to match your individual circumstances.</div>
                                        <div style="color: #6084a4; margin-top: 8px">You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</div>

                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Second NIW Case Builder (Optional)</span> -> select "Entrepreneur" or "Consultant" employment type in the checkout (Document #3A)</li>
                                        <div style="color: #6084a4; margin-top: 5px">If you're a business owner, entrepreneur, or consultant, you have the option to customize our standard NIW Case Builder, which is originally designed for employees. When going through the checkout process, you can select up to two types of employment. If you choose only the "employee" employment type, Document #3A will not be included in your package.</div>
                                    
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Our Original (Anonymized) Successful I-140 Cover Letter</span> -> banking industry (Document #4)</li>
                                    <li style="margin-left: 0rem; margin-top: 5px"><span style="font-weight: 500">Our Three Original (Anonymized) Reference Letters</span> -> banking industry (Documents #5A, #5B, #5C)</li>
                                    <li style="margin-left: 0rem; margin-top: 5px"><span style="font-weight: 500">Editable Original Reference Letters</span> -> banking industry (Documents #6A, #6B, #6C)</li>
                                        <div style="color: #6084a4; margin-top: 5px">Important even for other professions - as a template of another successful application. The I-140 cover letter and three reference letters originate from our successfully approved NON-RFE petition. We've taken the sensitive personal information and confidential content from the initial petition and made it anonymous for the Starter Kit. The arguments, structure, logic and appendix have all been retained as they were in the original submission.</div>

                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Guide and E-mail Samples to Request Reference Letters</span> (Document #7)</li>
                                        <div style="color: #6084a4; margin-top: 5px">When drafting personalized reference letters, it is crucial to avoid generic content that may not withstand USCIS scrutiny. For this reason, we found that drafting reference letters yourself and sending them to referees was the most optimal. Within this document, we detail the steps we took to acquire reference letters, offer our recommended strategies, and provide email draft templates that you can utilize when getting in touch with your referees.</div>
                                    
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">A Guide Explaining How to Understand the Visa Bulletin</span> (Document #8)</li>
                                        <div style="color: #6084a4; margin-top: 5px">Relevant for concurrent filing or adjustment of status/consular processing. A quick guide how to find out if your date is current, ie. there is no backlog and no wait time for a green card.</div>
                                    
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Continuation of the endeavor statement </span> -> banking industry (Bonus)</li>
                                        <div style="color: #6084a4; margin-top: 5px">The Continuation of Endeavor statement is an essential component of your EB-2 NIW petition. It outlines your plans to pursue your work in the U.S. and contribute to national interests. We provide an anonymized sample from our successful filing.</div>
                                    
                                    <li style="margin-left: 0rem; margin-top: 20px"><span style="font-weight: 500">Exclusive Lifetime Access to the Self Petition USA Discord Community</span></li>
                                        <div style="color: #6084a4; margin-top: 5px">This is the place to explore additional materials or ask questions about the process and get ongoing updates.</div>
                                </ul>
                            
                            <div style="margin: 1rem; max-width: 1000px;">*** When you purchase the Banking / Finance Starter Kit, you'll receive eight documents, except for the Sample Cover Letter specific to your profession (Document #2). The reason is that our original approved petition (Document #4) is from the banking industry itself and there is no need for a duplicate document.</div>

                            <h6 class="subTitleFaq">Add-ons</h6>
                                <ul style="margin-top: 1rem">
                                    <li style="margin-left: 0rem; margin-top: 5px"><span style="font-weight: 500">Adjustment of Status (I-485, I-765, I-131)</span> -> 10+ page guide and 50+ page editable sample</li>
                                    <li style="margin-left: 0rem; margin-top: 5px"><span style="font-weight: 500">ETA-9089 and ETA-750</span> -> sample forms</li>
                                    <li style="margin-left: 0rem; margin-top: 5px"><span style="font-weight: 500">Q&A Consultation</span> -> My own experience of applying for a green card</li>
                                </ul>
                            
                            <CarouselGreenCard style="margin-top: 7rem"></CarouselGreenCard>
                        </div>
                    </div>

                    <div id="reference-letters" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('three')">Do you get reference letters? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.three">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">With <a href="/#selfpetitionAI" style="text-decoration: underline; font-weight: 500">SELFPETITION AI</a>, you receive three customized reference letters, with the option to buy additional ones as needed. These letters are coordinated with the content of your AI-drafted cover letter and reflect your actual experiences with the referrers.</div>
                            <div style="margin: 1rem; max-width: 1000px">The <a href="/#starterKitProfessions" style="text-decoration: underline; font-weight: 500">STARTER KIT</a> includes three original (banking) reference letters as examples and a guide on how to prepare and request your own. The purpose of these sample letters is not for direct use as templates, but to provide insight into their structure. This helps you understand the layout and how each letter supports a different claim in the main petition.</div>
                            <div style="margin: 1rem; max-width: 1000px">It's important to remember that the primary objective of reference letters is to verify the accuracy of the statements in your petition. Therefore, it's crucial that these letters contain arguments that enhance your credibility and substantiate the assertions you've presented in your cover letter, but with additional and supporting details.</div>
                            <div style="margin: 1rem; max-width: 1000px">When composing personalized reference letters, it's essential to avoid generic content that might not hold up under the scrutiny of USCIS. To address this concern, we have determined that creating the reference letters yourself and then sending them to your referees yields the most effective results.</div>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/#carousel">Starter Kit Reference Letters (Documents: #5A-5C, #6A-6C and #7)</a>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/ai">Bespoke Reference Letters with Selfpetition AI</a>
                        </div>
                    </div>
                    




                    <div id="niw-case-builder" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('four')">What is NIW Case Builder (Starter Kit)? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.four">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">We offer profession-specific sample cover letter (Document #2) to provide guidance for creating your own. It's important to recognize that the responsibilities of mid-level and senior professionals, as well as the same role across various industries can vary. As a result, the provided letter serves as a sample and is not intended for direct submission to USCIS without modifications. Essentially, it functions as a customizable template that needs to be adjusted for your circumstances.</div>
                            <div style="margin: 1rem; max-width: 1000px">You might be curious about pinpointing relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf proves valuable – it sparks ideas for alternative focal points in your cover letter as you modify our template.</div>
                            <div style="margin: 1rem; max-width: 1000px">For individuals who are business owners, entrepreneurs, or consultants, there is an opportunity to customize our standard NIW Case Builder, which was originally designed for employees. During the checkout process, you can select a maximum of two employment types, where you receive additional NIW Case Builder for entrepreneur/business owner or a consultant.</div>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/#carousel">Visualize NIW Case Builder (Document #3)</a>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/ai">For bespoke petition draft see Selfpetition AI</a>
                        </div>
                    </div>

                    <div id="banking-application" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('five')">Why Include Banking Application (Starter Kit)?<span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.five">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">The inclusion of our original (banking) application for orders other than the Banking/Finance template serves several purposes.</div>
                            <div style="margin: 1rem; max-width: 1000px">Firstly, it allows you to thoroughly examine additional application to identify patterns and cross-reference it with our original reference letters. We provide guidance on preparing winning reference letters in Document #7 (Requesting Reference Letters). In essence, you begin by drafting your cover letter and then leverage the same claims and arguments in the reference letters. These letters play a crucial role in validating the assertions presented in the application.</div>
                            <div style="margin: 1rem; max-width: 1000px">Assuming you have ordered any of our other templates and are not a banking professional, our original (banking) application offers valuable insights into structuring arguments and presenting evidence clearly and compellingly. It provides a template for conveying complex information to the USCIS agent, who, like you, may not have a background in the specific profession. It's essential to maintain straightforward language, avoid unnecessary jargon, and clarify any profession-specific terms to enhance clarity for the reader. Review our banking application from the perspective of a USCIS clerk – were you able to grasp the presented arguments easily? You should write your own petition in a similar manner.</div>
                            <div style="margin: 1rem; max-width: 1000px">Furthermore, the original cover letter serves as a guide on leveraging certain universal arguments and phrases. By incorporating these elements into your application, you can enhance its overall persuasiveness and increase its chances of success.</div>
                            <div style="margin: 1rem; max-width: 1000px">In summary, including our original banking application aims to ensure consistency, provide a stylistic guide for presenting arguments, and leverage universal elements to strengthen the overall impact of your EB-2 NIW application for a different profession. If you have any further questions or require additional clarification, please feel free to reach out before you purchase the Starter Kit.</div>
                            
                            <div style="margin: 1rem; max-width: 1000px">
                                <a href="/our-track-record" style="font-style: italic; text-decoration: underline">Read More &rarr;</a>
                            </div>
                        </div>
                    </div>

                    <div id="discord" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('six')">What to expect from Discord? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.six">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Self Petition USA Discord community is a dedicated online space where you can access extra materials, ask questions, and receive ongoing updates about the EB-2 NIW process. Connect with others who are going through the same journey, get suggestions and tips, and stay informed about any changes that may affect your application.</div>
                            <div style="margin: 1rem; max-width: 1000px">
                                <a href="/our-track-record" style="font-style: italic; text-decoration: underline">Read More &rarr;</a>
                            </div>
                        </div>
                    </div>

                    <div id="our-track-record" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('seven')">What is our track record? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.seven">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Our business grows organically, all thanks to happy customers who spread the word about their successes.</div>
                            <div style="margin: 1rem; max-width: 1000px">Up until May 2024, we've been able to help almost 140 applicants applying for the EB-2 NIW. Some of them purchased our Starter Kit, others Selfpetition AI service. And guess what? Almost half of them have also hopped on board our Discord Community.</div>
                            <div style="margin: 1rem; max-width: 1000px">
                                <a href="/our-track-record" style="font-style: italic; text-decoration: underline">Read More &rarr;</a>
                            </div>
                            <div class="row" style="margin: 0">
                                <img class="col-lg-4 col-md-6 col-12" style="margin: 1rem; border: #5457c1 solid 1px; padding: 0" src="../resources/stripe.webp">
                            </div>
                            <div style="margin: 1rem; max-width: 1000px">Read testimonials on our website and Google reviews:
                                <div style="margin-top: 1.5rem">
                                    <a target="_blank" href="https://maps.google.com/?cid=14962197312661238312">
                                        <img src="../resources/googleLogo.webp" style="width: 100px; margin-right: 8px" alt="Google reviews self petition usa"/>
                                    </a>
                                    <a target="_blank" href="https://www.trustpilot.com/review/selfpetitionusa.com">
                                        <img src="../resources/trustpilot-image.webp" style="width: 105px; margin-left: 8px" alt="Trustpilot reviews self petition usa"/>
                                    </a>
                                </div>
                            </div>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="https://maps.google.com/?cid=14962197312661238312">Read Google reviews</a>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="https://www.trustpilot.com/review/selfpetitionusa.com">Read Trustpilot reviews</a>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/#reviews">Testimonials</a>
                        </div>
                    </div>

                    <div id="cancelation-policy" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('eight')">Is there a cancelation policy? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.eight">
                            <div class="bar" style="margin-left: 0"></div>
                            <h6 class="subTitleFaq">Selfpetition AI</h6>
                                <div style="margin: 1rem; max-width: 1000px">If you are not satisfied with the cover letter after revisions, we offer a $500 refund and will not proceed with drafting reference letters. For using Selfpetition AI to respond to an RFE, we provide a full refund if the RFE response is unsuccessful.</div>
                            <h6 class="subTitleFaq">Starter Kit</h6>
                                <div style="margin: 1rem; max-width: 1000px">Considering the specific characteristics of the services provided and the content of the EB-2 NIW Starter Kit, any fees that are paid for these offerings are established as non-refundable.</div>
                                <div style="margin: 1rem; max-width: 1000px">It implies that once a payment is made, there will be no reimbursement or repayment of that amount, even if the purchaser decides not to proceed with using the service or product.</div>
                                <div style="margin: 1rem; max-width: 1000px">This is because we lack the capability to remove materials you've received electronically or prevent you from utilizing them. Starter Kit is a set of Word and Pdf documents that we send to your e-mail.</div>
                                <div style="margin: 1rem; max-width: 1000px">Please take your time to ensure that our products and services align with your expectations before proceeding with payment. If you have any inquiries, such as those regarding the contents and functionalities of the Starter Kit or any other questions, please don't hesitate to reach out to us for clarification. We make every effort to respond promptly and comprehensively, aiming to assist individuals whom we can genuinely support. Our business grows organically, thanks to happy customers who share their success stories and recommend us to others.</div>
                        </div>
                    </div>

                    <div id="legal" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('nine')">Do we provide legal advice? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.nine">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Self Petition USA is not a law firm, operates independently of a law firm and does not employ lawyers or attorneys. Consequently, we are unable to dispense legal counsel or provide any type of legal advice.</div>
                            <div style="margin: 1rem; max-width: 1000px">Starter Kit includes templates for various professional roles, which customers must adapt to their own discretion to suit USCIS submission requirements. All content and materials are intended solely for general informational purposes and are rooted in our past and private encounter with the EB-2 NIW process.</div>
                            <div style="margin: 1rem; max-width: 1000px"> Whereas Selfpetition AI, provides a draft reflecting your background, however it should not be considered a replacement for legal advice as we are not legal professionals and do not offer legal guidance. Selfpetition AI serves as a tool for drafting the petition, suggesting well-organized and logical arguments. However, you bear the responsibility for deciding on the content of your application. This is due to our limited knowledge of your background, legal status, and inability to verify the accuracy of your inputs.</div>
                            <div style="margin: 1rem; max-width: 1000px">Please always bear in mind that our circumstances (EB-2 NIW past applicants) may have diverged from yours. For legal inquiries, it is imperative to seek advice from an attorney capable of tailoring guidance to your distinct personal circumstances. Furthermore, we cannot assure the success of your EB-2 NIW petition, as we lack control over the manner and timing of your application.</div>
                            <div style="margin: 1rem; max-width: 1000px">All assertions presented are contingent upon specific conditions, susceptible to potential modifications, liable to obsolescence upon application, possibly inapplicable to your particular scenario, subject to temporal constraints, and are not legally or financially binding. The cost of an EB-2 NIW petition may fluctuate based on your individual situation. In view of the nature of our services, the fees rendered are non-refundable.</div>
                            <div style="margin: 1rem; max-width: 1000px">Self Petition USA does not warrant the precision or legitimacy of the information provided and hereby renounces liability for any loss or damage sustained as a result of errors or omissions. Additionally, Self Petition USA bears no responsibility for materials or information encountered on external sites or obtained directly from partners.</div>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" target="_blank" href="/Greencard_Terms_of_Service.pdf">Terms of Service</a>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" target="_blank" href="/Greencard_Privacy_Policy.pdf">Privacy Policy</a>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" target="_blank" href="/Greencard_Website_Terms_and_Conditions.pdf">Terms and Conditions</a>
                        </div>
                    </div>

                    <div id="eligible-nationalities" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('ten')">What nationalities can file? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.ten">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">There are no restrictions based on nationality when submitting Form I-140.</div>
                            <div style="margin: 1rem; max-width: 1000px">However, those intending to apply for adjustment of status or consular processing must ensure that the priority date for their nationality (based on the country of birth) is currently valid. It's worth noting that there is often a significant backlog for individuals from China and India.</div>
                            <div style="margin: 1rem; max-width: 1000px">The priority dates are subject to monthly changes, and the USCIS is actively dedicated to addressing the existing backlog. To stay updated, you can review the current and upcoming bulletin through the following link:</div>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" target="_blank" href="https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin.html">Check priority date in Visa Bulletin</a>
                            <div style="margin: 1rem; margin-top: 0.5rem; max-width: 1000px; color: #6084a4">>>> In the bulletin look for 2nd under category Employment-based</div>
                        </div>
                    </div>

                    <div id="costs" class="section-title faq" style="padding-top: 5rem">
                        <h2 class="titleFaq" v-on:click="faqToggle('eleven')">How much does it all cost? <span class="faq-list" style="cursor: pointer;">&#10753;</span></h2>
                        <div v-if="descriptionVisible.eleven">
                            <div class="bar" style="margin-left: 0"></div>
                            <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Typically, when pursuing an EB-2 National Interest Waiver (NIW), you have the option to initiate the process independently or with the assistance of an attorney. Self Petition USA operates without legal involvement, and you have the choice to engage a lawyer at your own discretion.</div>
                            <ul style="margin-top: 1rem">
                                <li style="margin-left: 0rem; margin-top: 5px">The USCIS filing fees have recently increased. Please ensure to check the latest fees <a href="https://www.uscis.gov/i-140" target="_blank" style="text-decoration: underline; font-weight: 500">HERE</a>.</li>
                                <li style="margin-left: 0rem; margin-top: 5px">Additionally, if you choose to use our EB2 NIW Starter Kit, the price is $360. For a more customized solution with Selfpetition AI, the cost is $1,000. Please note that we sometimes offer discounts.</li>
                                <li style="margin-left: 0rem; margin-top: 5px">Based on our research involving over 30 lawyers during our preparation for filing an EB-2 NIW application, enlisting the services of a lawyer typically entails standard legal charges of approximately $8,000-15,000 for the main applicant. Dependents, including children and spouses, often receive a discounted rate of approximately 50%. The exact fee might differ depending on factors such as the complexity of your case, the state where the lawyer practices, and the particular lawyer you decide to work with. You can self petition with or without a lawyer.</li>
                            </ul>

                            <div style="margin: 1rem; margin-top: 1.5rem; max-width: 1000px">Even if you apply with a lawyer, you will still need to prepare reference letters and possibly also a cover letter:</div>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/#starterKitProfessions">Starter Kit templates</a>
                            <a style="margin-left: 1rem; max-width: 1000px; text-decoration: underline" href="/ai">Selfpetition AI</a>
                        </div>
                    </div>

                </div>

    </section>

</template>


<script>

import CarouselGreenCard from "./CarouselGreenCard.vue";
import ProductsCompared from "./ProductsCompared.vue";


    export default {
        name: 'FAQ',
        components: {
            CarouselGreenCard,
            ProductsCompared
        },
        data() {
            return {
                containsFaq: true,
                descriptionVisible: {
                    one: false,
                    two: false,
                    three: false,
                    four: false,
                    five: false,
                    six: false,
                    seven: false,
                    eight: false,
                    nine: false,
                    ten: false,
                    eleven: false
                }
            };
        },
        mounted() {
            setTimeout(function(){
                if (this.$route.params.location === 'NiwCaseBuilder') {
                    this.$scrollTo('#niw-case-builder');
                } else if (this.$route.params.location === 'ReferenceLetters') {
                    this.$scrollTo('#reference-letters');
                } else if (this.$route.params.location === 'BankingApplication') {
                    this.$scrollTo('#banking-application');
                } else if (this.$route.params.location === 'Discord') {
                    this.$scrollTo('#discord');
                } else if (this.$route.params.location === 'Decision') {
                    this.$scrollTo('#your-decision');
                } else if (this.$route.params.location === 'TrackRecord') {
                    this.$scrollTo('#our-track-record');
                } else if (this.$route.params.location === 'CancelationPolicy') {
                    this.$scrollTo('#cancelation-policy');
                } else if (this.$route.params.location === 'Legal') {
                    this.$scrollTo('#legal;');
                } else if (this.$route.params.location === 'EligibleNationalities') {
                    this.$scrollTo('#eligible-nationalities');
                } else if (this.$route.params.location === 'Costs') {
                    this.$scrollTo('#costs');
                } else if (this.$route.params.location === 'DocumentsList') {
                    this.$scrollTo('#documents-list');
                }

            }.bind(this), 100)
        },
        methods: {
            faqToggle(faqNumber) {
                this.descriptionVisible[faqNumber] = !this.descriptionVisible[faqNumber];
            }
        }
    }

</script>
