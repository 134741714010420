<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Tech Entrepreneur" size="xl" hide-footer>
        <img src="../resources/sample-techEntrepreneur.webp" alt="eb2 niw sample entrepreneur"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Tech Entrepreneur" size="xl" hide-footer>
        <img src="../resources/caseBuilder-techEntrepreneur.webp" alt="eb2 niw sample tech entrepreneur"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/techEntrepreneur.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample entrepreneur"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Tech Entrepreneur</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the dynamic world of entrepreneurship, individuals pursuing the EB-2 National Interest Waiver encounter distinct challenges and opportunities tailored to the commercial landscape. For entrepreneurs, securing a successful petition goes beyond demonstrating business acumen; it necessitates a persuasive presentation of their venture's substantial impact on the U.S. economy, technological innovation, and societal welfare.</div>
          <div style="margin: 1rem; max-width: 1000px">The EB-2 NIW process for entrepreneurs demands an in-depth exposition of their achievements, underscoring the strategic role their enterprises play in enhancing and transforming business solutions. This involves detailing successful business initiatives, innovative products or services, and pioneering strategies that have significantly influenced market dynamics and economic outcomes.</div>
          <div style="margin: 1rem; max-width: 1000px">With their expertise inherently aligned with national interests, entrepreneurial applicants can position themselves as key drivers of economic innovation and societal progress. This alignment is recognized and valued by policymakers, reflecting the national interest in cultivating a vibrant economic ecosystem, promoting technological progress, and navigating the complexities of modern economic challenges.</div>
          <div style="margin: 1rem; max-width: 1000px">It is important to note that, contrary to common belief, extensive financial backing or existing business success is not obligatory for the EB-2 NIW. Our templates are specifically designed for entrepreneurs, focusing on the strategic impact and broader implications of their business ventures on national and economic development.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-biotech-startup-founder" target="_blank" style="text-decoration: underline">Success Story 1</a></li>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-hr-consultant" target="_blank" style="text-decoration: underline">Success Story 2</a></li>
            </ul>
          </div>
        </div>

<!--Tech Entrepreneur-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Tech Entrepreneur</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-techEntrepreneur.webp" alt="entrepreneur i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Tech Entrepreneur</h3>
                <h3 style="color: #fff">Cover Letter (11 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-techEntrepreneur.webp" alt="tech entrepreneur green card">
              <div class="works-content">
                <h3 style="color: #fff">Tech Entrepreneur</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (22 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Entrepreneur</li>
                <li class="profession-page-list">Consultant</li>
                <li class="profession-page-list">Business Owner</li>
                <li class="profession-page-list">Startup Founder</li>
                <li class="profession-page-list">Business Innovator</li>
                <li class="profession-page-list">Venture Creator</li>
                <li class="profession-page-list">Enterprise Developer</li>
                <li class="profession-page-list">Business Pioneer</li>
                <li class="profession-page-list">Serial Entrepreneur</li>
                <li class="profession-page-list">Company Owner</li>
                <li class="profession-page-list">Sole Proprietor</li>
                <li class="profession-page-list">Franchise Holder</li>
                <li class="profession-page-list">Retail Owner</li>
                <li class="profession-page-list">Independent Businessman/woman</li>
                <li class="profession-page-list">Advisor</li>
                <li class="profession-page-list">Specialist</li>
                <li class="profession-page-list">Industry Consultant</li>
                <li class="profession-page-list">Technical Advisor</li>
                <li class="profession-page-list">Professional Advisor</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Artificial intelligence</li>
                <li class="profession-page-list">Recruitment automation</li>
                <li class="profession-page-list">Job creation</li>
                <li class="profession-page-list">Workforce expansion</li>
                <li class="profession-page-list">Innovation stimulation</li>
                <li class="profession-page-list">AI screening tools</li>
                <li class="profession-page-list">Operational scaling</li>
                <li class="profession-page-list">Business plan</li>
                <li class="profession-page-list">Business strategy</li>
                <li class="profession-page-list">Business growth</li>
                <li class="profession-page-list">Startup development</li>
                <li class="profession-page-list">Business innovation</li>
                <li class="profession-page-list">Scale-up strategies</li>
                <li class="profession-page-list">Entrepreneurial leadership</li>
                <li class="profession-page-list">Market disruption</li>
                <li class="profession-page-list">Growth hacking</li>
                <li class="profession-page-list">Competitive strategy</li>
                <li class="profession-page-list">Product development</li>
                <li class="profession-page-list">Funding strategies</li>
                <li class="profession-page-list">Funding strategies</li>
                <li class="profession-page-list">Bootstrapping</li>
                <li class="profession-page-list">Value proposition</li>
                <li class="profession-page-list">Financial forecasting</li>
                <li class="profession-page-list">Angel investing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw entrepreneur"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw tech entrepreneur"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionTechEntrepreneur',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'techEntrepreneur',
            employmentType: 'entrepreneur',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Tech Entrepreneur Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1,
              employmentType: this.user.employmentType
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
      }
    }
</script>

