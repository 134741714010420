<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom', {'is-sticky': isSticky}]">
        <div class="startp-nav">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a href="/" class="navbar-brand">
                        <img width="150px" src="./../resources/selfpetitionusa-logo.svg" alt="Self Petition USA"/>
                    </a>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>


                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <ul class="navbar-nav nav ml-auto">

                            <li class="nav-item">
                                <a href="/" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Home</a>
                            </li>
                            <li class="nav-item">
                                <a href="/#starter-kit" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Starter Kit &#9997;&#127996;</a>
                            </li>

                            <li class="nav-item">
                                <a href="/#selfpetition-ai" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Selfpetition AI &#129302;</a>
                            </li>

                            <li class="nav-item">
                                <a href="/#rfes" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">RFEs <span style="font-size: 18px; margin-left: 2px">&#127919;</span></a>
                            </li>

                            <li class="nav-item">
                                <a href="/#reviews" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Reviews</a>
                            </li>
                            <li class="nav-item">
                                <a href="/prices" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Pricing</a>
                            </li>
                            <li class="nav-item">
                                <a href="/contact" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Contact</a>
                            </li>

                            <li class="nav-item">
                                <a href="/faq" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">FAQ</a>
                            </li>
                            <li class="nav-item">
                                <a href="/blog/" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Blog</a>
                            </li>
                        </ul>
                    </b-collapse>

                    <div class="others-option"></div>
                </nav>

                <div id="aiBarContainer" class="ai-bar-container">
                    <div class="ai-bar">
                        <img width="40px" src="./../resources/newIcon.webp" alt="Self-Petition USA"/>Draft EB2 NIW with AI <a class="btn-ai" href="/ai">Learn more -></a>
                    </div>
                </div>

            </div>

    </header>
    <!-- End Navbar Area -->
</template>

<script>

import { mapActions } from 'vuex';

export default {
    name: 'HeaderGreenCard',
    data(){
        return {
            isSticky: false
        }
    },
    mounted() {
       const that = this;
       window.addEventListener('scroll', () => {
               let scrollPos = window.scrollY;

               if(scrollPos >= 100){
                   that.isSticky = true;
               } else {
                   that.isSticky = false;
               }
       });

    },
    methods: {
        ...mapActions('account', ['apply']),
        sendEvent(eventType) {
          if (window.gtag) {
            window.gtag('event', eventType); // google analytics
          }
        }
    },
}
</script>
