<template>
    <!-- Start Fun Facts Area -->
    <section class="funfacts-area ptb-80" style="padding-bottom: 1.5rem; margin-top: 1rem">
        <div class="container" style="padding-top: 0">
            <div class="section-title">
                <h2>Our experience</h2>
                <div class="bar"></div>
                <p>As of 2024, we served almost 200 self-petitioners, with the EB-2 NIW success rate per USCIS 2023 statistics being 91% for STEM and 80% across all industries. <a href="stem-approvals">Source -></a></p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            {{customers}}
                        </span>+</h3>
                        <div style="font-weight: 500">Customers</div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                                {{successStem}}
                            </span>%</h3>
                        <div style="font-weight: 500">Success Rate (STEM)</div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                                {{successAll}}
                            </span>%</h3>
                        <div style="font-weight: 500">Success Rate (All)</div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                                 {{professions}}
                            </span>+</h3>
                        <div style="font-weight: 500">Professions served</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Fun Facts Area -->
</template>

<script>
export default {
    name: 'Funfacts',
    data() {
        return {
            customers: 182,
            successStem: 90.9,
            successAll: 79.8,
            professions: 30,
        }
    }
}
</script>