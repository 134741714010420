var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"styled-table-products",staticStyle:{"margin-top":"4rem","margin-bottom":"4rem"}},[_vm._m(0),_c('tbody',[_vm._m(1),_vm._m(2),_c('tr',[_c('th',[_vm._v("Who writes the documents")]),(_vm.containsFaq)?_c('td',[_vm._v("You, based on "),_c('a',{staticStyle:{"text-decoration":"underline","font-weight":"500"},attrs:{"href":"#niw-case-builder"}},[_vm._v("NIW Case Builder")]),_vm._v(" document")]):_c('td',[_vm._v("You, based on "),_c('a',{staticStyle:{"text-decoration":"underline","font-weight":"500"},attrs:{"href":"/faq#niw-case-builder"}},[_vm._v("NIW Case Builder")]),_vm._v(" document")]),_c('td',[_vm._v("Done by AI, based on your background input")])]),_vm._m(3),_c('tr',[_c('th',[_vm._v("Documents included")]),(_vm.containsFaq)?_c('td',[_vm._v("Cover letter, three (banking) reference letters samples, and other supporting documents. Full list of Starter Kit documents "),_c('a',{staticStyle:{"text-decoration":"underline","font-weight":"500"},attrs:{"href":"#documents-list"}},[_vm._v("HERE")])]):_c('td',[_vm._v("Cover letter, three (banking) reference letters samples, and other supporting documents. Full list of Starter Kit documents "),_c('a',{staticStyle:{"text-decoration":"underline","font-weight":"500"},attrs:{"href":"/faq#documents-list"}},[_vm._v("HERE")])]),_c('td',[_vm._v("Cover letter and reference letters reflecting your unique background")])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"20%"}},[_vm._v("Products:")]),_c('th',{staticStyle:{"width":"40%"}},[_vm._v("Starter Kit")]),_c('th',{staticStyle:{"width":"40%"}},[_vm._v("Selfpetition AI")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Purpose")]),_c('td',[_vm._v("EB2 NIW")]),_c('td',[_vm._v("EB2 NIW")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Customization")]),_c('td',[_vm._v("Standard template provided for you to personalize")]),_c('td',[_vm._v("Already tailored to your unique background, ready to file")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Available professions")]),_c('td',[_vm._v("Only profession listed on the website")]),_c('td',[_vm._v("Any profession")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Reference letters")]),_c('td',[_vm._v("Three reference letters samples (banking) and Guide how to draft your own")]),_c('td',[_vm._v("Bespoke reference letters aligned with AI-drafted cover letter and your background")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Format")]),_c('td',[_vm._v("Word and PDF")]),_c('td',[_vm._v("Word (Google Docs)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Discord Community")]),_c('td',[_vm._v("Yes")]),_c('td',[_vm._v("Yes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Delivery")]),_c('td',[_vm._v("Receive the template within 24h")]),_c('td',[_vm._v("First draft in 48h, finalized usually in 5-7 days (depending on number of requested revisions)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Review by Self Petition USA")]),_c('td',[_vm._v("None")]),_c('td',[_vm._v("Yes, we review the draft before sending it to you, and you can request unlimited updates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Cancelation")]),_c('td',[_vm._v("None")]),_c('td',[_vm._v("Partial refund available under our satisfaction guarantee")])])
}]

export { render, staticRenderFns }