<template>
  
    <section class="services-area ptb-80" style="background: white; padding-bottom: 4rem; padding-top: 0rem; margin-top: 3rem">
        <div class="container">
            
            <div id="starterKitProfessions" class="section-title">
                <h2>Starter Kit Templates &#9997;&#127996;</h2>
                <div class="bar"></div>

                <div class="paragraph">
                    <p style="margin-top: 10px">Can't find your profession?</p>
                    <p>Check <a href="/ai" style="text-decoration: underline">Selfpetition AI</a> or <a href="/contact" style="text-decoration: underline">contact us</a></p>

                    <div class="star-section">
                        <div class="row">
<!-- small screens-->                        
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/banking-finance-sample" style="text-decoration: underline">Banking & Finance</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/software-engineer-it-sample" style="text-decoration: underline">Software Engineer</a></p>
                                </div>
                            </div>
                            
                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/software-engineer-it-sample" style="text-decoration: underline">IT</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/data-scientist-analyst-sample" style="text-decoration: underline">Data Scientist</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/data-scientist-analyst-sample" style="text-decoration: underline">Data Analyst</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/product-project-mgmt-sample" style="text-decoration: underline">Product & Project Manager</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/network-electrical-engineer-sample" style="text-decoration: underline">Network & Electrical Engineer</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/electro-mechanical-engineer-sample" style="text-decoration: underline">Electro-mechanical Eng.</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/chemical-engineer-sample" style="text-decoration: underline">Chemical Engineer</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/marketing-sample" style="text-decoration: underline">Marketing Professional</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/hr-recruiter-sample" style="text-decoration: underline">HR & Recruiter</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/science-teacher-sample" style="text-decoration: underline">Science Teacher</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/physician-dentist-sample" style="text-decoration: underline">Physician & Dentist</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/pilot-sample" style="text-decoration: underline">Pilot</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/pilot-sample" style="text-decoration: underline">Pilot & Aeronautical Eng.</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input"><a href="/tech-entrepreneur-sample" style="text-decoration: underline">Tech Entrepreneur</a></p>
                                </div>
                            </div>

                            <div class="d-block d-sm-none col-10 offset-2">
                                <div class="star-row">
                                    <font-awesome-icon class="star-icon" icon="star" />
                                    <p class="star-input">Brick-and-mortar Business</p>
                                </div>
                            </div>

<!-- desktop screens-->
                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/banking-finance-sample" style="text-decoration: underline">Banking & Finance</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/software-engineer-it-sample" style="text-decoration: underline">Software Engineer</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/software-engineer-it-sample" style="text-decoration: underline">IT</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/data-scientist-analyst-sample" style="text-decoration: underline">Data Scientist</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/data-scientist-analyst-sample" style="text-decoration: underline">Data Analyst</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/product-project-mgmt-sample" style="text-decoration: underline">Product & Project Manager</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/network-electrical-engineer-sample" style="text-decoration: underline">Network & Electrical Engineer</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/electro-mechanical-engineer-sample" style="text-decoration: underline">Electro-mechanical Eng.</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/chemical-engineer-sample" style="text-decoration: underline">Chemical Engineer</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/marketing-sample" style="text-decoration: underline">Marketing Professional</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/hr-recruiter-sample" style="text-decoration: underline">HR & Recruiter</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/science-teacher-sample" style="text-decoration: underline">Science Teacher</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/physician-dentist-sample" style="text-decoration: underline">Physician & Dentist</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/pilot-sample" style="text-decoration: underline">Pilot</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/pilot-sample" style="text-decoration: underline">Pilot & Aeronautical Eng.</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-4 col-5 offset-lg-1 offset-0">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input"><a href="/tech-entrepreneur-sample" style="text-decoration: underline">Tech Entrepreneur</a></p>
                                    </div>
                                </div>

                                <div class="d-none d-sm-block col-lg-3 col-5 offset-lg-0 offset-1">
                                    <div class="star-row">
                                        <font-awesome-icon class="star-icon" icon="star" />
                                        <p class="star-input">Brick-and-mortar Busin.</p>
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <CarouselGreenCard style="margin-top: 5rem; padding-bottom: 0"></CarouselGreenCard>

            <div class="shape3-services"><img src="../resources/shape3.svg" alt="i-140"></div>
            <div class="shape4-services"><img src="../resources/shape4.svg" alt="i-485"></div>

        </div>
    </section>

</template>

<script>
import CarouselGreenCard from "./CarouselGreenCard.vue";

    export default {
        name: 'StarterKitGreenCard',
        components: {
          CarouselGreenCard,
        }
    }
</script>
