<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <HeaderGreenCard v-else></HeaderGreenCard>
    <div>
      <ContactGreenCard style="padding-top: 10rem"></ContactGreenCard>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <FooterGreenCard v-else></FooterGreenCard>
  </div>

</template>

<script>
  import HeaderGreenCard from "./components/HeaderGreenCard";
  import FooterGreenCard from "./components/FooterGreenCard";
  import ContactGreenCard from './components/ContactGreenCard';


  export default {
    name: 'HomeGreenCardContact',
    components: {
      HeaderGreenCard,
      FooterGreenCard,
      ContactGreenCard,
    },
    data() {
      return {
        currentUrl: ''
      }
    },

    watch: {
      '$route'(pathUrl){
        this.currentUrl = pathUrl.path;
      }
    },

    mounted() {
      this.currentUrl = window.location.pathname;
    },
    metaInfo() {
      return {
        title: "Contact us",
        meta: [
          { vmid: 'description', name: 'description', content: "As of 2024, we served almost 200 self-petitioners, with the EB-2 NIW success rate per USCIS 2023 statistics being 91% for STEM and 80% across all industries."},
        ]
      }
    }
  }
</script>
