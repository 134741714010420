<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Data Scientist" size="xl" hide-footer>
        <img src="../resources/sample-dataScientist.webp" alt="eb2 niw sample data science"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Data Scientist" size="xl" hide-footer>
        <img src="../resources/caseBuilder-dataScientist.webp" alt="eb2 niw sample data scientist"/>
      </b-modal>

      <b-modal ref="modal-cover-letter-2" title="Data Analyst" size="xl" hide-footer>
        <img src="../resources/sample-dataAnalyst.webp" alt="eb2 niw sample data analysis"/>
      </b-modal>

      <b-modal ref="modal-case-builder-2" title="Data Analyst" size="xl" hide-footer>
        <img src="../resources/caseBuilder-dataAnalyst.webp" alt="eb2 niw sample data analyst"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/dataAnalyst.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample data"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Data Scientist <br>& Data Analyst</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Market analysts, data analysts, statisticians, and data scientists grapple with the challenge of not only showcasing their analytical prowess but also highlighting the profound impact of their contributions on the business landscape. This involves providing an intricate account of data-driven insights, innovative methodologies, or contributions that have positively influenced industries, decision-making processes, or overall business performance within the realm of data analysis and science.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process in the data sector demands a comprehensive presentation of the applicant's accomplishments, accentuating the pivotal role they play in shaping the nation's data landscape. It is equally crucial to underscore the broader societal and economic implications of their work in data analysis and science.</div>
          <div style="margin: 1rem; max-width: 1000px">Given the inherent alignment of data expertise with national interests, applicants can leverage their roles as catalysts for technological advancement and informed decision-making. This alignment has been duly recognized and emphasized by policymakers (Biden Administration in January 2022.), acknowledging the pivotal role of the data sector in propelling innovation and progress.</div>
          <div style="margin: 1rem; max-width: 1000px">It's noteworthy that, contrary to common belief, research, publications, or citations are not obligatory for EB-2 NIW, and our templates are specifically tailored for professionals in data analysis and science without these traditional markers of achievement.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-data-analyst" target="_blank" style="text-decoration: underline">Success Story 1</a></li>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-data-scientist" target="_blank" style="text-decoration: underline">Success Story 2</a></li>
            </ul>
          </div>
        </div>

<!--Data Scientist-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Data Scientist</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckoutFirst" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-dataScientist.webp" alt="data science i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Data Scientist</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-dataScientist.webp" alt="data science green card">
              <div class="works-content">
                <h3 style="color: #fff">Data Scientist</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (19 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>

  <!--Data Analyst-->
          <div style="margin: 6rem 0rem 5rem">
            <h4 style="text-align: center;">Data Analyst</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckoutSecond" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal2" style="cursor: pointer">
              <img src="../resources/sample-dataAnalyst.webp" alt="data analyst i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Data Analyst</h3>
                <h3 style="color: #fff">Cover Letter (8 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal2" style="cursor: pointer">
              <img src="../resources/caseBuilder-dataAnalyst.webp" alt="data analyst green card">
              <div class="works-content">
                <h3 style="color: #fff">Data Analyst</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (18 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Data Analysts</li>
                <li class="profession-page-list">Data Scientists</li>
                <li class="profession-page-list">Market Analysts</li>
                <li class="profession-page-list">Statisticians</li>
                <li class="profession-page-list">Business Analyst</li>
                <li class="profession-page-list">Research Analyst</li>
                <li class="profession-page-list">Financial Analyst</li>
                <li class="profession-page-list">Quantitative Analyst</li>
                <li class="profession-page-list">Risk Analyst</li>
                <li class="profession-page-list">Competitive Intelligence Analyst</li>
                <li class="profession-page-list">Business Intelligence Analyst</li>
                <li class="profession-page-list">Operations Research Analyst</li>
                <li class="profession-page-list">Pricing Analyst</li>
                <li class="profession-page-list">Econometrician</li>
                <li class="profession-page-list">Health Data Analyst</li>
                <li class="profession-page-list">Social Media Analyst</li>
                <li class="profession-page-list">Supply Chain Analyst</li>
                <li class="profession-page-list">Market Researcher</li>
                <li class="profession-page-list">Data Engineer</li>
                <li class="profession-page-list">Predictive Modeler</li>
                <li class="profession-page-list">Computational Scientist</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Machine Learning solutions</li>
                <li class="profession-page-list">Artificial Intelligence (AI) applications</li>
                <li class="profession-page-list">Technology</li>
                <li class="profession-page-list">Advanced algorithms</li>
                <li class="profession-page-list">Software applications development</li>
                <li class="profession-page-list">Predictive modeling</li>
                <li class="profession-page-list">Deep learning solutions</li>
                <li class="profession-page-list">Cloud Computing</li>
                <li class="profession-page-list">Voice recognition software</li>
                <li class="profession-page-list">Operational efficiency improvement</li>
                <li class="profession-page-list">Data preprocessing</li>
                <li class="profession-page-list">Reinforcement learning</li>
                <li class="profession-page-list">Data analytics</li>
                <li class="profession-page-list">Data-driven operational efficiency</li>
                <li class="profession-page-list">Cost optimization</li>
                <li class="profession-page-list">Revenue increase</li>
                <li class="profession-page-list">Automation of technology processes</li>
                <li class="profession-page-list">Semiconductor industry</li>
                <li class="profession-page-list">Speech recognition industry</li>
                <li class="profession-page-list">Microchips</li>
                <li class="profession-page-list">Electronic components</li>
                <li class="profession-page-list">Data accuracy and consistency</li>
                <li class="profession-page-list">Inefficiency identification</li>
                <li class="profession-page-list">U.S. technological leadership</li>
                <li class="profession-page-list">Economic growth</li>
                <li class="profession-page-list">Economic independence</li>
                <li class="profession-page-list">National security</li>
                <li class="profession-page-list">Supply chain security</li>
                <li class="profession-page-list">Job creation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw data scientist"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw data analyst"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionDataScientistAnalyst',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'dataScientist',
            profession2: 'dataAnalyst',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckoutFirst() {
          //this.apply('Data Scientist Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        navigateToCheckoutSecond() {
          //this.apply('Data Analyst Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession2
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
        openCoverLetterModal2() {
          setTimeout(() => {
            this.$refs['modal-cover-letter-2'].show();
          }, 100);
        },
        openCaseBuilderModal2() {
          setTimeout(() => {
            this.$refs['modal-case-builder-2'].show();
          }, 100);
        },
      }
    }
</script>