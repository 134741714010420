<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <HeaderGreenCard v-else></HeaderGreenCard>
    <div>
      <PricingGreenCard style="padding-top: 10rem"></PricingGreenCard>
      <FeedbackGreenCard2></FeedbackGreenCard2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <FooterGreenCard v-else></FooterGreenCard>
  </div>

</template>

<script>
  import HeaderGreenCard from "./components/HeaderGreenCard";
  import FooterGreenCard from "./components/FooterGreenCard";
  import PricingGreenCard from './components/PricingGreenCard';
  import FeedbackGreenCard2 from './components/FeedbackGreenCard2';

  export default {
    name: 'HomeGreenCardPricing',
    components: {
      HeaderGreenCard,
      FooterGreenCard,
      PricingGreenCard,
      FeedbackGreenCard2
    },
    data() {
      return {
        currentUrl: ''
      }
    },

    watch: {
      '$route'(pathUrl){
        this.currentUrl = pathUrl.path;
      }
    },

    mounted() {
      this.currentUrl = window.location.pathname;
    },
    metaInfo() {
      return {
        title: "Pricing",
        meta: [
          { vmid: 'description', name: 'description', content: "We prioritize transparency in our pricing structure. No hidden fees or surprises – what you see is what you get. Our commitment is to provide you with a clear understanding of the investment you're making in your future."},
        ]
      }
    }
  }
</script>
