var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer-area bg-f7fafd"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-6"},[_c('div',{staticClass:"single-footer-widget"},[_vm._m(0),_c('p',{staticStyle:{"font-weight":"600"}},[_vm._v("Self-petition green card for professionals")]),_c('p',[_vm._v("Take matters into your own hands!")]),_c('p',{staticStyle:{"margin-bottom":"0px"}},[_c('font-awesome-icon',{staticClass:"fa-1x fa-rotate-90",staticStyle:{"margin-right":"5px"},attrs:{"icon":"phone"}}),_vm._v(" (+1) 650 880 5823")],1),_c('p',[_c('font-awesome-icon',{staticClass:"fa-1x",staticStyle:{"margin-right":"5px"},attrs:{"icon":"envelope"}}),_vm._v(" ceo@selfpetitionusa.com")],1),_vm._m(1),_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('img',{staticClass:"map",attrs:{"src":require("../resources/map.png"),"alt":"national interest waiver examples"}}),_vm._m(6),_vm._m(7),_c('back-to-top',{attrs:{"bottom":"50px","right":"50px"}},[_c('div',{staticClass:"go-top"},[_c('vue-feather',{attrs:{"type":"arrow-up"}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"width":"150px","src":require("../resources/selfpetitionusa-logo.svg"),"alt":"Self Petition USA"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://www.trustpilot.com/review/selfpetitionusa.com"}},[_c('img',{staticStyle:{"text-align":"left","width":"110px","margin-top":"1.5rem","margin-right":"15px"},attrs:{"src":require("../resources/trustpilot-image.webp"),"alt":"Trustpilot reviews self petition usa"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://maps.google.com/?cid=14962197312661238312"}},[_c('img',{staticStyle:{"text-align":"left","width":"110px","margin-top":"1.5rem"},attrs:{"src":require("../resources/googleLogo.webp"),"alt":"Google reviews self petition usa"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-6 col-sm-6"},[_c('div',{staticClass:"single-footer-widget pl-5"},[_c('h3',[_vm._v("Company")]),_c('ul',{staticClass:"list"},[_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/#starter-kit"}},[_vm._v("Starter Kit ✍🏼")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/#selfpetition-ai"}},[_vm._v("Selfpetition AI 🤖")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/#rfes"}},[_vm._v("RFEs "),_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("🎯")])])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/#reviews"}},[_vm._v("Reviews")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/prices"}},[_vm._v("Pricing")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-6 col-sm-6"},[_c('div',{staticClass:"single-footer-widget"},[_c('h3',[_vm._v("Support")]),_c('ul',{staticClass:"list"},[_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/Greencard_Terms_of_Service.pdf"}},[_vm._v("Terms of Service")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/Greencard_Privacy_Policy.pdf"}},[_vm._v("Privacy Policy")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/Greencard_Website_Terms_and_Conditions.pdf"}},[_vm._v("Terms and Conditions")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/blog/"}},[_vm._v("Blog")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/faq"}},[_vm._v("FAQ")])]),_c('li',[_c('a',{staticStyle:{"height":"25px"},attrs:{"href":"/contact"}},[_vm._v("Contact")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"copyright-area"},[_c('p',[_vm._v("Copyright ©2024 Self Petition USA. All Rights Reserved")]),_c('div',{staticStyle:{"text-align":"justify","text-justify":"inter-word"}},[_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v("The information provided on this website does not constitute legal advice, and we cannot guarantee the success of your EB-2 NIW petition as we have no control over how and when you apply. The content presented on this site should not be considered as formal legal advice or the establishment of a lawyer-client relationship. Self Petition USA is not a legal firm and does not hire lawyers. If in doubt it is important to consult with a lawyer who can provide guidance tailored to your unique personal situation.")]),_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v("All statements made are subject to certain conditions, may be subject to change, could be outdated at the time of application, may not be applicable to your specific circumstances, may have a limited timespan and are not legally or financially binding. The pricing for an EB-2 NIW petition may vary depending on your individual situation. Due to the nature of our services, the fees you pay are non-refundable.")]),_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v("Self Petition USA does not guarantee the accuracy or validity of the information provided and hereby disclaims any liability for any loss or damage incurred as a result of errors or omissions. Additionally, Self Petition USA is not responsible for any materials or information found on external sites provided by partnering lawyers or obtained directly from partners.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../resources/shape1.png"),"alt":"national interest waiver templates"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../resources/shape2.svg"),"alt":"niw templates"}})])
}]

export { render, staticRenderFns }