<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <HeaderGreenCard v-else></HeaderGreenCard>
    <div>
      <ProfessionPilot></ProfessionPilot>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <FooterGreenCard v-else></FooterGreenCard>
  </div>

</template>

<script>
    import HeaderGreenCard from "./components/HeaderGreenCard";
    import FooterGreenCard from "./components/FooterGreenCard";
    import ProfessionPilot from "./components/ProfessionPilot";

    export default {
      name: 'ProfessionPilotView',
      components: {
        ProfessionPilot,
        HeaderGreenCard,
        FooterGreenCard,
      },
      data() {
        return {
          currentUrl: ''
        }
      },
      watch: {
        '$route'(pathUrl){
          this.currentUrl = pathUrl.path;
        }
      },
      mounted() {
        this.currentUrl = window.location.pathname;
      },
      metaInfo() {
        return {
          title: "Pilot & Aeronautical Engineer - Self Petition USA",
          meta: [
          { vmid: 'description', name: 'description', content:  ""},
          ]
        }
      }
    }
</script>