<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Science Teacher" size="xl" hide-footer>
        <img src="../resources/sample-scienceTeacher.webp" alt="eb2 niw sample teacher"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Science Teacher" size="xl" hide-footer>
        <img src="../resources/caseBuilder-scienceTeacher.webp" alt="eb2 niw sample science teacher"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/scienceTeacher.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample teacher"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Science Teacher</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
        <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the ever-evolving landscape of scientific education, educators embarking on the path of guiding young minds through the intricacies of biology, physics, and chemistry encounter a unique array of challenges and opportunities. For science teachers, the journey towards excellence in their field extends beyond the transmission of textbook knowledge; it involves illuminating the profound impact of their instructional methods and curricular innovations on shaping the scientific minds of the future.</div>
          <div style="margin: 1rem; max-width: 1000px">The process of showcasing one's qualifications for the EB-2 NIW as a science teacher demands a thoughtful presentation of achievements, accentuating the strategic role played in advancing science education. This involves delving into the intricacies of successful teaching methodologies, curriculum development initiatives, and innovative approaches that have significantly influenced the educational landscape and student outcomes.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process for science teachers necessitates a comprehensive approach. Applicants must highlight their instrumental role in fostering a love for science, optimizing teaching processes, and contributing to the overall academic success of students. Emphasizing the broader societal and economic implications of their work, within the context of modern science education dynamics, is crucial. Advancements in science education align with national interests, promoting scientific literacy, technological innovation, and a workforce prepared for the challenges of a rapidly advancing world.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of science education expertise with national interests, applicants can position themselves as catalysts for scientific advancement, educational efficiency, and talent development. This alignment is recognized and emphasized by policymakers, reflecting the national interest in cultivating a scientifically literate population, fostering innovation, and addressing the evolving dynamics in science education.</div>
          <div style="margin: 1rem; max-width: 1000px">It's important to note that, contrary to common belief, extensive research, publications, or citations are not obligatory for EB-2 NIW. Our templates are specifically tailored for science teachers, focusing on the strategic impact and broader implications of their contributions to science education and the development of future scientists.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-science-teacher" target="_blank" style="text-decoration: underline">Success Story</a></li>
            </ul>
          </div>
        </div>

<!--Science Teacher-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Science Teacher</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-scienceTeacher.webp" alt="STEM teacher i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Science Teacher</h3>
                <h3 style="color: #fff">Cover Letter (10 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-scienceTeacher.webp" alt="science teacher green card">
              <div class="works-content">
                <h3 style="color: #fff">Science Teacher</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (19 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Science Teacher</li>
                <li class="profession-page-list">Physics Teacher</li>
                <li class="profession-page-list">Mathematics Teacher</li>
                <li class="profession-page-list">Chemistry Teacher</li>
                <li class="profession-page-list">Biology Teacher</li>
                <li class="profession-page-list">STEM Tutor</li>
                <li class="profession-page-list">Science Tutor</li>
                <li class="profession-page-list">STEM Teacher</li>
                <li class="profession-page-list">Environmental Science Teacher</li>
                <li class="profession-page-list">Computer Science Teacher</li>
                <li class="profession-page-list">Programming Tutor</li>
                <li class="profession-page-list">Early Childhood Education Teacher</li>
                <li class="profession-page-list">Middle School Teacher</li>
                <li class="profession-page-list">High School Teacher</li>
                <li class="profession-page-list">Robotics Instructor</li>
                <li class="profession-page-list">Engineering Educator</li>
                <li class="profession-page-list">Astronomy Teacher</li>
                <li class="profession-page-list">Geology Tutor</li>
                <li class="profession-page-list">Statistics Teacher</li>
                <li class="profession-page-list">Data Science Instructor</li>
                <li class="profession-page-list">Biotechnology Educator</li>
                <li class="profession-page-list">Earth Science Teacher</li>
                <li class="profession-page-list">Neuroscience Educator</li>
                <li class="profession-page-list">Bioinformatics Instructor</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">STEM</li>
                <li class="profession-page-list">STEM education advocacy</li>
                <li class="profession-page-list">STEM teacher support</li>
                <li class="profession-page-list">Underrepresented communities in STEM</li>
                <li class="profession-page-list">Diversity in STEM</li>
                <li class="profession-page-list">Education conferences</li>
                <li class="profession-page-list">STEM curriculum development</li>
                <li class="profession-page-list">High school STEM programs</li>
                <li class="profession-page-list">Science competitions mentoring</li>
                <li class="profession-page-list">Project-based learning for STEM</li>
                <li class="profession-page-list">Teachers association</li>
                <li class="profession-page-list">Education policy and advocacy</li>
                <li class="profession-page-list">Corporate social responsibility in STEM</li>
                <li class="profession-page-list">Educational technology integration</li>
                <li class="profession-page-list">Inclusion in STEM organizations</li>
                <li class="profession-page-list">After-school activities</li>
                <li class="profession-page-list">Advancements in STEM education</li>
                <li class="profession-page-list">Education and workforce development</li>
                <li class="profession-page-list">STEM outreach and mentorship</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw science teacher"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw stem teacher"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionScienceTeacher',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'scienceTeacher',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Science Teacher Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
      }
    }
</script>

