<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Chemical Engineer" size="xl" hide-footer>
        <img src="../resources/sample-chemicalEngineer.webp" alt="eb2 niw sample chemical engineer"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Chemical Engineer" size="xl" hide-footer>
        <img src="../resources/caseBuilder-chemicalEngineer.webp" alt="eb2 niw sample chemical engineer"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/chemicalEng.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample chemical engineer"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Chemical Engineer <br> (Oil & Petroleum)</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In pursuit of the EB-2 NIW, chemical engineers are entrusted with not only showcasing their technical prowess but also highlighting the extensive impact of their work on the nation's well-being. This entails presenting a detailed account of innovations, advancements, or contributions that have positively influenced industries, society, or national security within the realm of chemical engineering.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process demands a comprehensive presentation of the engineer's accomplishments, emphasizing the pivotal role they play in advancing the nation's technological landscape in the field of chemical engineering. Furthermore, it is crucial to underscore the broader societal and economic implications of their work in this domain.</div>
          <div style="margin: 1rem; max-width: 1000px">It is reasonable to presume that STEM fields, particularly chemical engineering, inherently align with national interests, given their crucial role in the contemporary era of technological innovation. This alignment has been explicitly acknowledged by the Biden Administration in January 2022.</div>
          <div style="margin: 1rem; max-width: 1000px">It's noteworthy that, contrary to common belief, research, publications, or citations are not obligatory for EB-2 NIW, and our templates are specifically tailored for professionals in chemical engineering without the traditional markers of achievement.</div>
          
          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-petroleum-engineer" target="_blank" style="text-decoration: underline">Success Story</a></li>
            </ul>
          </div>
        </div>

<!--Chemical Engineer-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Chemical Engineer</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-chemicalEngineer.webp" alt="chemical engineer i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Chemical Engineer</h3>
                <h3 style="color: #fff">Cover Letter (8 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-chemicalEngineer.webp" alt="chemical engineer green card">
              <div class="works-content">
                <h3 style="color: #fff">Chemical Engineer</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (18 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Chemical Engineer</li>
                <li class="profession-page-list">Product Development Engineer</li>
                <li class="profession-page-list">Quality Control Engineer</li>
                <li class="profession-page-list">Process Engineer</li>
                <li class="profession-page-list">Environmental Engineer</li>
                <li class="profession-page-list">Health and Safety Engineer</li>
                <li class="profession-page-list">Bioprocess Engineer</li>
                <li class="profession-page-list">Materials Engineer</li>
                <li class="profession-page-list">Petroleum Engineer</li>
                <li class="profession-page-list">Energy Engineer</li>
                <li class="profession-page-list">Water Treatment Engineer</li>
                <li class="profession-page-list">Nuclear Engineer</li>
                <li class="profession-page-list">Plant Manager</li>
                <li class="profession-page-list">Instrumentation and Control Engineer</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Oil and gas efficiency projects</li>
                <li class="profession-page-list">Efficiency and productivity</li>
                <li class="profession-page-list">Environmental impact mitigation</li>
                <li class="profession-page-list">National security</li>
                <li class="profession-page-list">Economic growth</li>
                <li class="profession-page-list">Energy independence</li>
                <li class="profession-page-list">Global industry leadership</li>
                <li class="profession-page-list">Sustainable development</li>
                <li class="profession-page-list">Geopolitical stability</li>
                <li class="profession-page-list">Spills mitigation</li>
                <li class="profession-page-list">Eco-friendly solutions</li>
                <li class="profession-page-list">Global energy landscape</li>
                <li class="profession-page-list">U.S. economic competitiveness</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw chemical engineer"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw chemical engineering"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionChemicalEngineer',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'chemicalEngineer',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Chemical Engineer Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        }
      }
    }
</script>

