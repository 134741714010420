<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <HeaderGreenCard v-else></HeaderGreenCard>
    <div style="margin-top: 8rem">
      <StarterKitGreenCard></StarterKitGreenCard>
      <ContactGreenCard></ContactGreenCard>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <FooterGreenCard v-else></FooterGreenCard>
  </div>

</template>

<script>
  import HeaderGreenCard from "./components/HeaderGreenCard";
  import FooterGreenCard from "./components/FooterGreenCard";
  import StarterKitGreenCard from './components/StarterKitGreenCard';
  import ContactGreenCard from './components/ContactGreenCard';


  export default {
    name: 'HomeGreenCardStarterKit',
    components: {
      HeaderGreenCard,
      FooterGreenCard,
      StarterKitGreenCard,
      ContactGreenCard,
    },
    data() {
      return {
        currentUrl: ''
      }
    },

    watch: {
      '$route'(pathUrl){
        this.currentUrl = pathUrl.path;
      }
    },

    mounted() {
      this.currentUrl = window.location.pathname;
    },
    metaInfo() {
      return {
        title: "Starter Kit Templates",
        meta: [
          { vmid: 'description', name: 'description', content: "Discover a world of professionally crafted templates tailored to your specific needs. Whether you're in Banking & Finance, IT, Engineering, Marketing, Education, Healthcare, or even a Tech Entrepreneur, our collection of meticulously designed templates has you covered. Can't find a template that aligns perfectly with your profession? No worries – contact us for assistance, or explore our diverse range of SAMPLES below."},
        ]
      }
    }
  }
</script>
