<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Electro-mechanical Engineer" size="xl" hide-footer>
        <img src="../resources/sample-electromechanicalEngineer.webp" alt="eb2 niw sample electromechanical engineer"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Electro-mechanical Engineer" size="xl" hide-footer>
        <img src="../resources/caseBuilder-electromechanicalEngineer.webp" alt="eb2 niw sample electromechanical eng"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/electromechanicalEng.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw electromechanical engineer"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Electro-mechanical Engineer</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Professionals specializing in electromechanical engineering must not only showcase their technical expertise but also articulate the profound impact of their contributions on critical systems and/or industries. This involves providing a detailed account of their work in designing and optimizing power distribution and management systems, contributing to advancements in intelligent electrical grids, and enhancing fault tolerance within the industries.</div>
          <div style="margin: 1rem; max-width: 1000px">To successfully navigate the EB-2 NIW process in the electromechanical engineering sector, applicants must comprehensively present their accomplishments, emphasizing the pivotal role they play in shaping and advancing technological solutions. Equally crucial is underscoring the broader societal and economic implications of their work, where advancements align with national interests such as technological innovation and environmental sustainability.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of electromechanical expertise with national interests, applicants can leverage their roles as catalysts for technological progress and enhanced safety. This alignment has been duly recognized and emphasized by policymakers, reflecting the national interest in promoting innovation, addressing climate change, and ensuring energy independence.</div>
          <div style="margin: 1rem; max-width: 1000px">It's noteworthy that, contrary to common belief, research, publications, or citations are not obligatory for EB-2 NIW, and our templates are specifically tailored for professionals in electromechanical engineering without the need for these traditional markers of achievement.</div>
        
          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-automotive-engineer" target="_blank" style="text-decoration: underline">Success Story</a></li>
            </ul>
          </div>
        
        </div>

<!--Electro-mechanical Engineer-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Electro-mechanical Engineer</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-electromechanicalEngineer.webp" alt="electromechanical engineer i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Electro-mechanical Engineer</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-electromechanicalEngineer.webp" alt="electromechanical engineer green card">
              <div class="works-content">
                <h3 style="color: #fff">Electro-mechanical Engineer</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (18 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Electro-mechanical Engineer</li>
                <li class="profession-page-list">Automation Engineer</li>
                <li class="profession-page-list">Control Systems Engineer</li>
                <li class="profession-page-list">Robotics Engineer</li>
                <li class="profession-page-list">Mechatronics Engineer</li>
                <li class="profession-page-list">Instrumentation Engineer</li>
                <li class="profession-page-list">Systems Integration Engineer</li>
                <li class="profession-page-list">Electrical Design Engineer</li>
                <li class="profession-page-list">Manufacturing Engineer</li>
                <li class="profession-page-list">Energy Systems Engineer</li>
                <li class="profession-page-list">Process Engineer</li>
                <li class="profession-page-list">Electrical Project Manager</li>
                <li class="profession-page-list">Quality Assurance Engineer </li>
                <li class="profession-page-list">Maintenance Engineer</li>
                <li class="profession-page-list">Test Engineer</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">More Electric Aircraft (MEA)</li>
                <li class="profession-page-list">Aircraft power distribution</li>
                <li class="profession-page-list">Intelligent electrical grids</li>
                <li class="profession-page-list">Advanced power electronics</li>
                <li class="profession-page-list">Smart energy management systems</li>
                <li class="profession-page-list">Motion control products</li>
                <li class="profession-page-list">Sensing and propulsion solutions</li>
                <li class="profession-page-list">Electromechanical systems</li>
                <li class="profession-page-list">Aviation and defense products</li>
                <li class="profession-page-list">Circuit breakers, switches, contactors</li>
                <li class="profession-page-list">Power distribution units</li>
                <li class="profession-page-list">Energy management</li>
                <li class="profession-page-list">Aviation industry</li>
                <li class="profession-page-list">Department of Energy (DOE)</li>
                <li class="profession-page-list">National Aeronautics and Space Administration (NASA)</li>
                <li class="profession-page-list">U.S. technological leadership</li>
                <li class="profession-page-list">Global aviation leadership</li>
                <li class="profession-page-list">Aerospace and defense industries</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw electromechanical engineer"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw electromechanical engineering"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionElectroMechanicalEng',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'electromechanicalEngineer',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Electro-mechanical Engineer Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
      }
    }
</script>

