<template>

    <table class="styled-table-products" style="margin-top: 4rem; margin-bottom: 4rem">
        <thead>
            <tr>
                <th style="width: 20%">Products:</th>
                <th style="width: 40%">Starter Kit</th>
                <th style="width: 40%">Selfpetition AI</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Purpose</th>
                <td>EB2 NIW</td>
                <td>EB2 NIW</td>
            </tr>
            <tr>
                <th>Customization</th>
                <td>Standard template provided for you to personalize</td>
                <td>Already tailored to your unique background, ready to file</td>
            </tr>
            <tr>
                <th>Who writes the documents</th>
                <td v-if="containsFaq">You, based on <a href="#niw-case-builder" style="text-decoration: underline; font-weight: 500">NIW Case Builder</a> document</td>
                <td v-else>You, based on  <a href="/faq#niw-case-builder" style="text-decoration: underline; font-weight: 500">NIW Case Builder</a> document</td>
                <td>Done by AI, based on your background input</td>
            </tr>
            <tr>
                <th>Available professions</th>
                <td>Only profession listed on the website</td>
                <td>Any profession</td>
            </tr>
            <tr>
                <th>Documents included</th>
                <td v-if="containsFaq">Cover letter, three (banking) reference letters samples, and other supporting documents. Full list of Starter Kit documents <a href="#documents-list" style="text-decoration: underline; font-weight: 500">HERE</a></td>
                <td v-else>Cover letter, three (banking) reference letters samples, and other supporting documents. Full list of Starter Kit documents <a href="/faq#documents-list" style="text-decoration: underline; font-weight: 500">HERE</a></td>
                <td>Cover letter and reference letters reflecting your unique background</td>
            </tr>
            <tr>
                <th>Reference letters</th>
                <td>Three reference letters samples (banking) and Guide how to draft your own</td>
                <td>Bespoke reference letters aligned with AI-drafted cover letter and your background</td>
            </tr>
            <tr>
                <th>Format</th>
                <td>Word and PDF</td>
                <td>Word (Google Docs)</td>
            </tr>
            <tr>
                <th>Discord Community</th>
                <td>Yes</td>
                <td>Yes</td>
            </tr>
            <tr>
                <th>Delivery</th>
                <td>Receive the template within 24h</td>
                <td>First draft in 48h, finalized usually in 5-7 days (depending on number of requested revisions)</td>
            </tr>
            <tr>
                <th>Review by Self Petition USA</th>
                <td>None</td>
                <td>Yes, we review the draft before sending it to you, and you can request unlimited updates</td>
            </tr>
            <tr>
                <th>Cancelation</th>
                <td>None</td>
                <td>Partial refund available under our satisfaction guarantee</td>
            </tr>
        </tbody>
    </table>

</template>

<script>
    export default {
        name: 'ProductsCompared',
        props: {
            containsFaq: {
                type: Boolean,
                required: true
            }
        },
    }
</script>