<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <HeaderGreenCard ></HeaderGreenCard>
    <div>
      <div id="home"></div>
      <BannerGreenCard id="home"></BannerGreenCard>
           <div class="funfacts-area" style="z-index:0;">
              <div class="map-bg funfacts" style="padding-top: 10rem;">
                            <img src="./resources/map.png" alt="self petition" style="width: 70%">
            </div>
            </div>
      <Funfacts></Funfacts>

      <div id="starter-kit"></div>
      <StarterKitGreenCard></StarterKitGreenCard>
      <div id="selfpetition-ai"></div>
      <SelfpetitionAIGreenCard></SelfpetitionAIGreenCard>
      <div id="rfes"></div>
      <RfeGreenCard></RfeGreenCard>
      <div id="reviews"></div>
      <FeedbackGreenCard2></FeedbackGreenCard2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <FooterGreenCard v-else></FooterGreenCard>
  </div>

</template>

<script>
  import BannerGreenCard from './components/BannerGreenCard';
  import Funfacts from './components/Funfacts'
  import StarterKitGreenCard from './components/StarterKitGreenCard';
  import SelfpetitionAIGreenCard from './components/SelfpetitionAIGreenCard';
  import RfeGreenCard from './components/RfeGreenCard';
  import FeedbackGreenCard2 from './components/FeedbackGreenCard2';
  import HeaderGreenCard from "./components/HeaderGreenCard";
  import FooterGreenCard from "./components/FooterGreenCard";


  export default {
    name: 'HomeGreenCard',
    data() {
      return {
        currentUrl: '',
      };
    },
    components: {
      BannerGreenCard,
      Funfacts,
      StarterKitGreenCard,
      SelfpetitionAIGreenCard,
      RfeGreenCard,
      FeedbackGreenCard2,
      HeaderGreenCard,
      FooterGreenCard
    },
    watch: {
      '$route'(pathUrl){
        this.currentUrl = pathUrl.path;
      }
    },
    metaInfo() {
      return {
        title: "EB2 NIW TEMPLATE: Self petition green card for professionals",
        meta: [
          { vmid: 'description', name: 'description', content:  'Templates for EB2 NIW Advanced Degree. Green card Starter Kit to self-petition: successful I-140 cover letter, reference letters and Discord community.'},
        ]
      }
    }
  }

</script>
