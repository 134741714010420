<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Banking & Finance" size="xl" hide-footer>
        <img src="../resources/sample-banking.webp" alt="eb2 niw sample banking"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Banking & Finance" size="xl" hide-footer>
        <img src="../resources/caseBuilder-banking.webp" alt="eb2 niw sample finance"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/banker.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample investor"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Banking & Finance</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">Bankers, finance professionals, investors, and venture capitalists face the challenge of not only showcasing their financial expertise but also emphasizing the significant impact of their contributions on the economic landscape. This involves providing a detailed account of financial innovations, strategic investments, or contributions that have positively influenced industries, job creation, or economic stability within the realm of banking and finance.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process in the financial sector requires a comprehensive presentation of the applicant's achievements, highlighting the pivotal role they play in shaping the nation's economic future. It is also crucial to underscore the broader societal and economic implications of their work in the financial sector.</div>
          <div style="margin: 1rem; max-width: 1000px">Given the inherent alignment of financial expertise with national economic interests, applicants can leverage their roles as catalysts for economic growth. This alignment has been recognized and emphasized by policymakers, acknowledging the pivotal role of the financial sector in driving economic prosperity.</div>
          <div style="margin: 1rem; max-width: 1000px">It's noteworthy that, contrary to common belief, research, publications, or citations are not obligatory for EB-2 NIW, and our templates are specifically tailored for professionals in banking and finance without these traditional markers of achievement.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-investment-banker" target="_blank" style="text-decoration: underline">Success Story 1</a></li>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-finance-expert" target="_blank" style="text-decoration: underline">Success Story 2</a></li>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-the-head-of-investments-portfolio-capital" target="_blank" style="text-decoration: underline">Success Story 3</a></li>
            </ul>
          </div>
        </div>

<!--Banking & Finance-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Banking & Finance</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover"  @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-banking.webp" alt="banking i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Banking & Finance</h3>
                <h3 style="color: #fff">Cover Letter (8 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-banking.webp" alt="finance green card">
              <div class="works-content">
                <h3 style="color: #fff">Banking & Finance</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (18 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
             <div>

                    <div class="d-none d-sm-block">
                        <div class="row">
                            <ul class="col-lg-8 col-12" style="margin-top: 1rem; margin-bottom: 3rem; padding-left: 24px">
                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Starter Kit Instructions</li>
                                    <div>(Document #1)</div>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Sample I-140 Cover Letter</li>
                                    <div>Our original anonymized I-140 Cover Letter (Document #2/#4)</div>
                                    <a href="#profession-sample" class="btn-scroll">Review sample -></a>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">NIW Case Builder</li>
                                    <div>Profession of your choice (Document #3)</div>
                                    <a href="/faq#niw-case-builder" class="btn-scroll">What is NIW Builder -></a>
                                    <a href="#profession-sample" class="btn-scroll">Review sample -></a>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Second NIW Case Builder (Optional)</li>
                                    <div>Select "Entrepreneur" or "Consultant" employment type in the checkout (Document #3A)</div>
                                    <a href="/faq#niw-case-builder"  class="btn-scroll">What is NIW Builder -></a>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Our Three Original Anonymized Reference Letters</li>
                                <li style="margin-left: 0rem; margin-top: 3px; font-weight: 500">Editable Original Reference Letters</li>
                                    <div>Banking industry (#5A-5C, #6A-6C)</div>
                                    <a href="/faq#banking-application" class="btn-scroll">Why is banking application included -></a>
                                    <router-link :to="{ name: 'Faq', params: { location: 'ReferenceLetters' }}" class="btn-scroll">Do you receive reference letters -></router-link>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Guide and E-mail Samples to Request Reference Letters</li>
                                    <div>(Document #7)</div>
                                    <router-link :to="{ name: 'Faq', params: { location: 'ReferenceLetters' }}" class="btn-scroll">Do you receive reference letters -></router-link>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">A Guide Explaining How to Understand the Visa Bulletin</li>
                                    <div>(Document #8)</div>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Continuation of the endeavor statement</li>
                                    <div>(Bonus)</div>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Exclusive Lifetime Access to <img src="../resources/discord-logo.svg" style="width: 90px; margin-left: 3px; margin-right: 3px" alt="eb2 self petition"/> Community</li>
                                    <router-link :to="{ name: 'Faq', params: { location: 'Discord' }}" class="btn-scroll">What to expect from Discord -></router-link>
                            </ul>

                            <div class="col-lg-4 col-sm-6 col-12" style="margin: 0 auto">
                                <img src="../resources/stepsStarterKit.webp" alt="EB2 niw guide">
                            </div>

                            <div class="col-12 service-action-btns" style="margin-top: 3rem; margin-bottom: 1.5rem">
                                <router-link :to="{ name: 'Faq', params: { location: 'DocumentsList' }}" class="btn btn-primary">REVIEW DOCUMENTS</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="d-block d-sm-none">
                        <div class="row">
                            <ul class="col-12" style="margin-top: 1rem; margin-bottom: 3rem; padding-left: 24px">
                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Starter Kit Instructions</li>
                                    <div>(Document #1)</div>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Sample I-140 Cover Letter</li>
                                    <div>Our original anonymized I-140 Cover Letter</div>
                                    <div>(Document #2/#4)</div>
                                    <a href="#profession-sample" class="btn-scroll mobile">Review sample -></a>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">NIW Case Builder</li>
                                    <div>Profession of your choice</div>
                                    <div>(Document #3)</div>
                                    <a href="/faq#niw-case-builder"  class="btn-scroll mobile">What is NIW Builder -></a>
                                    <a href="#profession-sample" class="btn-scroll mobile">Review sample -></a>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Second NIW Case Builder (Optional)</li>
                                    <div>Select "Entrepreneur" or "Consultant" employment type in the checkout</div>
                                    <div>(Document #3A)</div>
                                    <a href="/faq#niw-case-builder" class="btn-scroll mobile">What is NIW Builder -></a>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Our Three Original Anonymized Reference Letters</li>
                                <li style="margin-left: 0rem; margin-top: 3px; font-weight: 500">Editable Original Reference Letters</li>
                                    <div>Banking industry</div>
                                    <div>(Documents #5A-5C, #6A-6C)</div>
                                    <a href="/faq#banking-application" class="btn-scroll mobile">Why is banking application added -></a>
                                    <router-link :to="{ name: 'Faq', params: { location: 'ReferenceLetters' }}" class="btn-scroll mobile">Do you receive reference letters -></router-link>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Guide and E-mail Samples to Request Reference Letters</li>
                                    <div>(Document #7)</div>
                                    <router-link :to="{ name: 'Faq', params: { location: 'ReferenceLetters' }}" class="btn-scroll mobile">Do you receive reference letters -></router-link>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">A Guide Explaining How to Understand the Visa Bulletin</li>
                                    <div>(Document #8)</div>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Continuation of the endeavor statement</li>
                                    <div>(Bonus)</div>

                                <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Exclusive Lifetime Access to Discord Community</li>
                                    <router-link :to="{ name: 'Faq', params: { location: 'Discord' }}" class="btn-scroll mobile">What to expect from Discord -></router-link>
                            </ul>

                            <div class="col-sm-8 col-12" style="margin: auto auto">
                                <img src="../resources/stepsStarterKit.webp" alt="EB2 niw guide">
                            </div>

                            <div class="col-12 service-action-btns" style="margin-top: 3rem; margin-bottom: 1.5rem">
                                <router-link :to="{ name: 'Faq', params: { location: 'DocumentsList' }}" class="btn btn-primary">REVIEW DOCUMENTS</router-link>
                            </div>
                        </div>
                    </div>

                </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Investment Banker</li>
                <li class="profession-page-list">Financial Analyst</li>
                <li class="profession-page-list">Risk Analyst</li>
                <li class="profession-page-list">Portfolio Manager</li>
                <li class="profession-page-list">Credit Analyst</li>
                <li class="profession-page-list">Private Banker</li>
                <li class="profession-page-list">Quantitative Analyst</li>
                <li class="profession-page-list">Commercial Banker</li>
                <li class="profession-page-list">Financial Advisor</li>
                <li class="profession-page-list">Hedge Fund Manager</li>
                <li class="profession-page-list">Derivatives Trader</li>
                <li class="profession-page-list">Compliance Officer</li>
                <li class="profession-page-list">Retail Banker</li>
                <li class="profession-page-list">Financial Controller</li>
                <li class="profession-page-list">Treasury Analyst</li>
                <li class="profession-page-list">Equity Research Analyst</li>
                <li class="profession-page-list">Commercial Real Estate Analyst</li>
                <li class="profession-page-list">Venture Capital Analyst</li>
                <li class="profession-page-list">Commodities Trader</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Regulations</li>
                <li class="profession-page-list">Cross-border transactions</li>
                <li class="profession-page-list">Initial Public Offerings (IPOs)</li>
                <li class="profession-page-list">Mergers & Acquisitions (M&A)</li>
                <li class="profession-page-list">Multi-million dollar investments</li>
                <li class="profession-page-list">Facilitating financial transactions between countries</li>
                <li class="profession-page-list">Financial certifications</li>
                <li class="profession-page-list">Job creation</li>
                <li class="profession-page-list">Corporate growth</li>
                <li class="profession-page-list">High-level financial analysis</li>
                <li class="profession-page-list">U.S.-EU trade and investment ties</li>
                <li class="profession-page-list">Managing international teams</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw banking"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw finance"></div>
    </section>
    
</template>

<script>

import { mapActions } from 'vuex';


    export default {
      name: 'ProfessionBankingFinance',
      data() {
        return {
          user: {
            profession1: 'bankingFinance',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Banking Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        }
      }
    }
</script>

