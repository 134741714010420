<template>
    <div>

        <div class="d-none d-sm-block">
            <div class="row">
                <ul class="col-lg-8 col-12" style="margin-top: 1rem; margin-bottom: 3rem; padding-left: 24px">
                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Starter Kit Instructions</li>
                        <div>(Document #1)</div>
                    
                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Sample I-140 Cover Letter</li>
                        <div>Profession of your choice (Document #2)</div>
                        <a href="#profession-sample" class="btn-scroll">Review sample -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">NIW Case Builder</li>
                        <div>Profession of your choice (Document #3)</div>
                        <a href="/faq#niw-case-builder"  class="btn-scroll">What is NIW Builder -></a>
                        <a href="#profession-sample" class="btn-scroll">Review sample -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Second NIW Case Builder (Optional)</li>
                        <div>Select "Entrepreneur" or "Consultant" employment type in the checkout (Document #3A)</div>
                        <a href="/faq#niw-case-builder"  class="btn-scroll">What is NIW Builder -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Our Original Anonymized I-140 Cover Letter</li>
                    <li style="margin-left: 0rem; margin-top: 3px; font-weight: 500">Our Three Original Anonymized Reference Letters</li>
                    <li style="margin-left: 0rem; margin-top: 3px; font-weight: 500">Editable Original Reference Letters</li>                        
                        <div>Banking, not profession of your choice (Documents #4, #5A-5C, #6A-6C)</div>
                        <a href="/faq#banking-application" class="btn-scroll">Why is banking application included -></a>
                        <a href="/faq#reference-letters" class="btn-scroll">Do you receive reference letters -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Guide and E-mail Samples to Request Reference Letters</li>
                        <div>(Document #7)</div>
                        <a href="/faq#reference-letters" class="btn-scroll">Do you receive reference letters -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">A Guide Explaining How to Understand the Visa Bulletin</li>
                        <div>(Document #8)</div>
                    
                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Continuation of the endeavor statement</li>
                        <div>(Bonus)</div>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Exclusive Lifetime Access to <img src="../resources/discord-logo.svg" style="width: 90px; margin-left: 3px; margin-right: 3px" alt="eb2 self petition"/> Community</li>
                        <a href="/faq#discord" class="btn-scroll">What to expect from Discord -></a>
                </ul>

                <div class="col-lg-4 col-sm-6 col-12" style="margin: 0 auto">
                    <img src="../resources/stepsStarterKit.webp" alt="EB2 niw guide">
                </div>
                
                <div class="col-12 service-action-btns" style="margin-top: 3rem; margin-bottom: 1.5rem">
                    <a href="/faq##documents-list" class="btn btn-primary">REVIEW DOCUMENTS</a>
                </div>
            </div>
        </div>

        <div class="d-block d-sm-none">
            <div class="row">
                <ul class="col-12" style="margin-top: 1rem; margin-bottom: 3rem; padding-left: 24px">
                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Starter Kit Instructions</li>
                        <div>(Document #1)</div>
                    
                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Sample I-140 Cover Letter</li>
                        <div>Profession of your choice</div>
                        <div>(Document #2)</div>
                        <a href="#profession-sample"  class="btn-scroll mobile">Review sample -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">NIW Case Builder</li>
                        <div>Profession of your choice</div>
                        <div>(Document #3)</div>
                        <a href="/faq#niw-case-builder"  class="btn-scroll mobile">What is NIW Builder -></a>
                        <a href="#profession-sample"  class="btn-scroll mobile">Review sample -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Second NIW Case Builder (Optional)</li>
                        <div>Select "Entrepreneur" or "Consultant" employment type in the checkout</div>
                        <div>(Document #3A)</div>
                        <a href="/faq#niw-case-builder"  class="btn-scroll mobile">What is NIW Builder -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Our Original Anonymized I-140 Cover Letter</li>
                    <li style="margin-left: 0rem; margin-top: 3px; font-weight: 500">Our Three Original Anonymized Reference Letters</li>
                    <li style="margin-left: 0rem; margin-top: 3px; font-weight: 500">Editable Original Reference Letters</li>                        
                        <div>Banking, not profession of your choice</div>
                        <div>(Documents #4, #5A-5C, #6A-6C)</div>
                        <a href="/faq#banking-application" class="btn-scroll mobile">Why is banking application added -></a>
                        <a href="/faq#reference-letters" class="btn-scroll mobile">Do you receive reference letters -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Guide and E-mail Samples to Request Reference Letters</li>
                        <div>(Document #7)</div>
                        <a href="/faq#reference-letters" class="btn-scroll mobile">Do you receive reference letters -></a>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">A Guide Explaining How to Understand the Visa Bulletin</li>
                        <div>(Document #8)</div>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Continuation of the endeavor statement</li>
                        <div>(Bonus)</div>

                    <li style="margin-left: 0rem; margin-top: 20px; font-weight: 500">Exclusive Lifetime Access to Discord Community</li>
                    <a href="/faq#discord"  class="btn-scroll mobile">What to expect from Discord -></a>
                </ul>

                <div class="col-sm-8 col-12" style="margin: auto auto">
                    <img src="../resources/stepsStarterKit.webp" alt="EB2 niw guide">
                </div>
                
                <div class="col-12 service-action-btns" style="margin-top: 3rem; margin-bottom: 1.5rem">
                <a href="/faq#documents-list"  class="btn btn-primary">REVIEW DOCUMENTS</a>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

    export default {
        name: 'DocumentsList',
        mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if(scrollPos >= 100){
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        })
    }
    }

</script>