<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Physician & Dentist" size="xl" hide-footer>
        <img src="../resources/sample-dentist.webp" alt="eb2 niw sample doctor"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Physician & Dentist" size="xl" hide-footer>
        <img src="../resources/caseBuilder-dentist.webp" alt="eb2 niw sample dentist"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/dentist.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample physician"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Physician & Dentist</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the ever-evolving landscape of healthcare, physicians and dentists navigating the EB-2 NIW process encounter a unique set of challenges and opportunities. For medical professionals, the journey toward a successful petition extends beyond showcasing clinical expertise; it involves articulating the profound impact of their contributions to patient care, medical innovation, and the overall advancement of healthcare services.</div>
          <div style="margin: 1rem; max-width: 1000px">The EB-2 NIW process for physicians and dentists requires a meticulous presentation of achievements, highlighting the strategic role played in shaping and advancing healthcare solutions. This demands a deep dive into the details of successful patient care initiatives, groundbreaking medical procedures, and innovative methodologies that have significantly influenced healthcare dynamics and patient outcomes.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process in healthcare necessitates a comprehensive approach. Applicants must underscore their instrumental role in providing high-quality medical care, optimizing healthcare processes, and contributing to the overall well-being of communities. Emphasizing the broader societal and economic implications of their work, within the context of modern healthcare dynamics, is paramount. Advancements in healthcare align with national interests, such as public health, medical innovation, and strategic talent management on a global scale.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of physician and dentist expertise with national interests, applicants can position themselves as catalysts for public health, medical advancements, and the overall enhancement of healthcare delivery. This alignment is recognized and emphasized by policymakers, reflecting the national interest in building a robust healthcare system, fostering medical innovation, and addressing the evolving dynamics in healthcare management.</div>
          <div style="margin: 1rem; max-width: 1000px">It's important to note that, contrary to common belief, extensive research, publications, or citations are not obligatory for EB-2 NIW. Our templates are specifically tailored for physicians and dentists, focusing on the strategic impact and broader implications of their contributions to healthcare and patient well-being.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/blog/eb-2-niw-approval-for-healthcare-professional" target="_blank" style="text-decoration: underline">Success Story</a></li>
            </ul>
          </div>
        </div>

<!--Physician & Dentist-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Physician & Dentist</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-dentist.webp" alt="dentist i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Physician & Dentist</h3>
                <h3 style="color: #fff">Cover Letter (8 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-dentist.webp" alt="dentist green card">
              <div class="works-content">
                <h3 style="color: #fff">Physician & Dentist</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (19 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Dentist</li>
                <li class="profession-page-list">Optometrist</li>
                <li class="profession-page-list">Orthodontist</li>
                <li class="profession-page-list">Podiatrist</li>
                <li class="profession-page-list">Chiropractor</li>
                <li class="profession-page-list">Dermatologist</li>
                <li class="profession-page-list">Psychiatrist</li>
                <li class="profession-page-list">Cardiologist</li>
                <li class="profession-page-list">Endocrinologist</li>
                <li class="profession-page-list">Gastroenterologist</li>
                <li class="profession-page-list">Ophthalmologist</li>
                <li class="profession-page-list">Neurologist</li>
                <li class="profession-page-list">Urologist</li>
                <li class="profession-page-list">Rheumatologist</li>
                <li class="profession-page-list">Allergist / Immunologist</li>
                <li class="profession-page-list">Obstetrician / Gynecologist</li>
                <li class="profession-page-list">Pediatrician</li>
                <li class="profession-page-list">Geriatrician</li>
                <li class="profession-page-list">Infectious Disease Specialist</li>
                <li class="profession-page-list">Otolaryngologist</li>
                <li class="profession-page-list">Anesthesiologist</li>
                <li class="profession-page-list">Plastic Surgeon</li>
                <li class="profession-page-list">Radiologist</li>
                <li class="profession-page-list">Emergency Medicine Physician</li>
                <li class="profession-page-list">Sports Medicine Physician</li>
                <li class="profession-page-list">Pathologist</li>
                <li class="profession-page-list">Genetic Counselor</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Dental business consulting</li>
                <li class="profession-page-list">Dental practice growth</li>
                <li class="profession-page-list">Oral health training</li>
                <li class="profession-page-list">Pediatric dentistry expertise</li>
                <li class="profession-page-list">Dental entrepreneurship</li>
                <li class="profession-page-list">Dental business seminars</li>
                <li class="profession-page-list">Dental industry training programs</li>
                <li class="profession-page-list">Business strategies</li>
                <li class="profession-page-list">Dental practice management</li>
                <li class="profession-page-list">Dental business solutions</li>
                <li class="profession-page-list">Dental office administration</li>
                <li class="profession-page-list">Scaling business</li>
                <li class="profession-page-list">Business development</li>
                <li class="profession-page-list">Staffing solutions</li>
                <li class="profession-page-list">Dental practice efficiency</li>
                <li class="profession-page-list">Professional development</li>
                <li class="profession-page-list">Dental service and staff shortages</li>
                <li class="profession-page-list">Job creation</li>
                <li class="profession-page-list">Industry growth opportunities</li>
                <li class="profession-page-list">Business networking</li>
                <li class="profession-page-list">Pediatric dentistry training</li>
                <li class="profession-page-list">Social media marketing</li>
                <li class="profession-page-list">Business webinars</li>
                <li class="profession-page-list">Dental service outsourcing</li>
                <li class="profession-page-list">Automation techniques</li>
                <li class="profession-page-list">U.S. dental workforce shortages</li>
                <li class="profession-page-list">Dental jobs in high demand</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw dentist"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw physician"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionPhysicianDentist',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'physicianDentist',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Physician Dentist Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
      }
    }
</script>

