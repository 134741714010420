<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <HeaderGreenCard v-else></HeaderGreenCard>
    <div>
      <AiForm2></AiForm2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <FooterGreenCard v-else></FooterGreenCard>
  </div>

</template>

<script>
    import HeaderGreenCard from "./components/HeaderGreenCard";
    import FooterGreenCard from "./components/FooterGreenCard";
    import AiForm2 from "./components/GreenCardAiForm2";

    export default {
        name: 'GreenCardAiForm2View',
        components: {
            AiForm2,
            HeaderGreenCard,
            FooterGreenCard,
        },
        data() {
            return {
              currentUrl: '',
            }
        },
        mounted() {
          this.currentUrl = window.location.pathname;
        },
        watch: {
          '$route'(pathUrl){
            this.currentUrl = pathUrl.path;
          }
        },
        metaInfo() {
            return {
                title: "Simplify your EB2 NIW drafting experience with AI",
                meta: [
                  { vmid: 'description', name: 'description', content:  'Unlock the power of SelfpetitionAI, your trusted companion in self petitioning for EB-2 NIW Advanced Degree category. Answer list of questions about your background and let AI draft your I-140 cover letter and three reference letters with precision and efficiency. Simplify your drafting experience – where innovation meets simplicity.'},
                ]
            }
        }
    }
</script>