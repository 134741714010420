<template>
    <!-- Start Main Banner -->
    <div class="main-banner" style="padding-top: 5rem; margin-top: 2rem; margin-bottom: 0rem; padding-bottom: 0rem">
        <section />
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container" style="padding-top: 0">
                    <div class="row" >
                        <div class="col-md-10 offset-lg-2 offset-xl-0">
                        <h1 style="padding-top: 30px"><span style="color: #5457c1">EB-2 NIW </span>for professionals</h1>
                        </div>
                    </div>
                    <div class="row h-100 justify-content-center align-items-center">
                            <div class="col-xl-6 col-lg-7">
                                <div class="hero-content">
                                    <div class="row">
                                        <div class="col-lg-11">
                                            
                                            <h5 style="margin-top: 1rem">DIY templates & custom drafting solutions</h5>

                                            <div class="tick-section">
                                                <div class="d-block d-sm-none">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input"><a href="/#starter-kit"><span style="font-weight: 600">Starter Kit templates</span> for you to customize</a></p>
                                                    </div>
                                                </div>

                                                    <div class="d-none d-sm-block">
                                                        <div class="tick-row">
                                                            <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                            <p class="tick-input"><a href="/#starter-kit"><span style="font-weight: 600">Starter Kit templates</span> for you to customize</a></p>
                                                        </div>
                                                    </div>

                                                <div class="d-block d-sm-none">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input"><a href="/#selfpetition-ai">Custom petition <span style="font-weight: 600">by Selfpetition AI</span> reflecting your unique case</a></p>
                                                    </div>
                                                </div>

                                                    <div class="d-none d-sm-block">
                                                        <div class="tick-row">
                                                            <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                            <p class="tick-input"><a href="/#selfpetition-ai">Custom petition <span style="font-weight: 600">by Selfpetition AI</span> reflecting your unique case</a></p>
                                                        </div>
                                                    </div>

                                                <div class="d-block d-sm-none">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input"><a href="/#rfes">Bespoke <span style="font-weight: 600">RFE response by Selfpetition AI</span></a></p>
                                                    </div>
                                                </div>

                                                    <div class="d-none d-sm-block">
                                                        <div class="tick-row">
                                                            <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                            <p class="tick-input"><a href="/#rfes">Bespoke <span style="font-weight: 600">RFE response by Selfpetition AI</span></a></p>
                                                        </div>
                                                    </div>
                                                
                                                <div class="d-block d-sm-none">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input"><a href="/prices">Editable Adjustment of Status Sample</a></p>
                                                    </div>
                                                </div>

                                                    <div class="d-none d-sm-block">
                                                        <div class="tick-row">
                                                            <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                            <p class="tick-input"><a href="/prices">Editable Adjustment of Status Sample</a></p>
                                                        </div>
                                                    </div>

                                                <div class="d-block d-sm-none">
                                                    <div class="tick-row">
                                                        <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                        <p class="tick-input"><a href="/prices">Exclusive <img src="../resources/discord-logo.svg" style="width: 110px; margin-left: 5px; margin-right: 5px" alt="discord self petition"/> Community</a></p>
                                                    </div>
                                                </div>

                                                    <div class="d-none d-sm-block">
                                                        <div class="tick-row">
                                                            <font-awesome-icon class="fa-2x tick-icon" icon="check-square" />
                                                            <p class="tick-input"><a href="/prices">Exclusive <img src="../resources/discord-logo.svg" style="width: 110px; margin-left: 5px; margin-right: 5px" alt="discord self petition"/> Community</a></p>
                                                        </div>
                                                    </div>

                                                <div style="margin-top: 40px; margin-bottom: 2rem">
                                                    <a target="_blank" href="https://maps.google.com/?cid=14962197312661238312">
                                                        <img src="../resources/googleLogo.webp" style="width: 90px; margin-right: 8px" alt="Google reviews self petition usa"/>
                                                    </a>
                                                    <a target="_blank" href="https://www.trustpilot.com/review/selfpetitionusa.com">
                                                        <img src="../resources/trustpilot-image.webp" style="width: 95px; margin-left: 8px" alt="Trustpilot reviews self petition usa"/>
                                                    </a>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-5 col-md-10">
                                <div class="row" style="padding-bottom: 2rem; padding-top: 0.5rem">
                                    <div class="col-lg-8 col-md-10 offset-lg-2 offset-md-1">

                                        <div v-if="!showWaitingGif" class="pricing-table" style="padding-top: 30px; padding-bottom: 10px;">
                                            <h5 style="margin-bottom: 40px">{{currentHeader}}</h5> 
                                            <div class="form-group" style="margin-left: 20px; margin-right: 20px; margin-bottom: 0">
                                                <input type="text" class="form-control" id="clientName" aria-describedby="clientName" placeholder="Your name" style="border-radius: 5px; border-color: rgba(74, 111, 138, 0.3); margin-bottom: 15px; background: rgba(74, 111, 138, 0.1);" v-model="nameConsultation">
                                                <input type="email" class="form-control" id="clientEmail" aria-describedby="clientEmail" placeholder="Your e-mail" style="border-radius: 5px; border-color: rgba(74, 111, 138, 0.3); background: rgba(74, 111, 138, 0.1);" v-model="emailConsultation" v-on:input="clearEmailError()">
                                                <form @submit.prevent="submit()" style="text-align: center; margin-top: 15px; width: 100%">
                                                    <button type="submit" class="btn btn-primary btn-consultation" alt="eb2 niw consultation">{{currentButton}}</button>
                                                </form>
                                                <div :class="{ nonvisible: isEmail || (!isEmail && !submitted) }">
                                                    <div style="font-weight: 500; padding-top: 8px; color: #ff4e00">Enter a valid email</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="showWaitingGif" style="padding: 3rem 0rem; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                            <img src="../resources/musical.gif" style="width: 15rem">
                                            <div  class="alert alert-primary text-center" role="alert">
                                                <div style="font-weight: 600; font-size: 1.3em">DO NOT REFRESH</div>
                                                <div style="margin-top: 8px">Directing you to calendar schedule</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    <PriceOffer></PriceOffer>

                </div>
            </div>
        </div>

        <div class="shape1"><img src="../resources/shape1.png" alt="eb2 niw green card"></div>
        <div class="shape3"><img src="../resources/shape3.svg" alt="eb2 visa"></div>
        <div class="shape4"><img src="../resources/shape4.svg" alt="eb-2 niw"></div>
        <div class="shape5" style="top: 55%"><img src="../resources/shape5.png" alt="eb-2 visa"></div>
        <div class="shape6 rotateme"><img src="../resources/shape4.svg" alt="eb-2 niw green card"></div>
        <div class="shape7"><img src="../resources/shape4.svg" alt="eb-2"></div>
    </div>
    <!-- End Main Banner -->
</template>



<script>

import PriceOffer from "./PriceOffer.vue";
import {mapActions} from "vuex";

    export default {
        name: 'BannerGreenCard',
        data () {
            return {
                emailConsultation: '',
                nameConsultation: '',
                currentHeader: '',
                currentButton: '',
                isEmail: false,
                submitted: false,
                showWaitingGif: false,
                consultationHeaders: [
                    "Evaluate Profile",
                    "Free Evaluation",
                    "Book a Call",
                    "Schedule a Call",
                    "Discuss your EB-2 NIW",
                    "Talk to an Expert"
                ],
                consultationButtons: [
                    "Schedule",
                    "Schedule",
                    "Book",
                    "Schedule",
                    "Schedule",
                    "Schedule"
                ],
            }
        },
        mounted() {
            this.showRandomConsultation();
        },
        components: {
          PriceOffer,
        },
        methods: {
            ...mapActions('account', ['apply']),
            showRandomConsultation() {
                const cacheKey = 'calendlyConsultationData';

                // Check if cache data exists
                const cachedData = JSON.parse(localStorage.getItem(cacheKey));
                if (cachedData) {
                    this.currentHeader = cachedData.header;
                    this.currentButton = cachedData.button;
                } else {
                    // If no cache, generate random data
                    const randomIndex = Math.floor(Math.random() * this.consultationHeaders.length);
                    this.currentHeader = this.consultationHeaders[randomIndex];
                    this.currentButton = this.consultationButtons[randomIndex];

                    // Store data in cache
                    localStorage.setItem(cacheKey, JSON.stringify({ header: this.currentHeader, button: this.currentButton }));
                }
            },
            validateEmail () {
                this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.emailConsultation);
            },
            clearEmailError () {
                this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.emailConsultation);
            },
            submit() {
                this.submitted = true;
                this.validateEmail();
                if(this.isEmail === true && this.submitted === true) {
                    this.showWaitingGif = true;
                    this.apply({
                        name: this.nameConsultation,
                        email: this.emailConsultation,
                        consultationHeader: this.currentHeader,
                        consultationButton: this.currentButton,
                    });
                    this.showWaitingGif = false;
                    setTimeout(() => {
                        window.location.href = 'https://calendly.com/selfpetitionusa/consultation' + '?email=' + this.emailConsultation + '&name=' + this.nameConsultation
                    }, 1000);
                }
            }
        }
    }

</script>
